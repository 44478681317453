import { useEffect, useState } from "react";
import useMedia from "./useMedia";

const DeviceSizes = {
  MOBILE: "(max-width: 799px)",
  TABLET: "(min-width: 800px)",
  DESKTOP: "(min-width: 1250px)",
  LARGE_DESKTOP: "(min-width: 1440px)",
};

export const SCREENS = {
  LARGE_DESKTOP: "large desktop",
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile",
};

export type Screens = {
  isLargeDesktop: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
};

export default function useScreen(): Screens {
  const screen = useMedia(
    [
      DeviceSizes.LARGE_DESKTOP,
      DeviceSizes.DESKTOP,
      DeviceSizes.TABLET,
      DeviceSizes.MOBILE,
    ],
    [SCREENS.LARGE_DESKTOP, SCREENS.DESKTOP, SCREENS.TABLET, SCREENS.MOBILE],
    ""
  );
  const [screenClient, setScreenClient] = useState<any>();

  useEffect(() => {
    setScreenClient(screen);
  }, [screen]);

  return {
    isDesktop:
      screenClient === SCREENS.DESKTOP ||
      screenClient === SCREENS.LARGE_DESKTOP ||
      screenClient === null,
    isTablet: screenClient === SCREENS.TABLET,
    isMobile: screenClient === SCREENS.MOBILE,
    isLargeDesktop: screenClient === SCREENS.LARGE_DESKTOP,
  };
}
