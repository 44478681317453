import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';

import styles from './CustomField.module.scss';
import { ToolTip } from '../Form';
import { WordCount } from '@/utils/common';

type TextInputFieldProps = {
  name: string;
  placeholder?: string;
  label: string;
  isRequired?: boolean;
  error?: string | undefined;
  touched?: boolean | undefined;
  wrapperClassname?: string;
  hasTooltip?: boolean;
  tooltipHeading?: string;
  tooltipContent?: string;
  grayPlaceholder?: boolean;
  isDisable?: boolean;
  tooltipClassname?: string;
  wordCount?: number;
  value?: string;
  wordCountClass?: string;
  isUserForm?: boolean;
};

const TextInputField = ({
  label,
  name,
  placeholder,
  error,
  touched,
  wrapperClassname,
  hasTooltip,
  tooltipHeading,
  tooltipContent,
  grayPlaceholder,
  isDisable,
  tooltipClassname,
  wordCount,
  value,
  isRequired,
  // eslint-disable-next-line
  wordCountClass,
  isUserForm,
}: TextInputFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const keyCodeRef = useRef<string | undefined>(undefined);
  const onPasteRef = useRef(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (error && error.trim().length > 0) {
      setHasError(true);
    }
  }, [error]);

  const handleChangeInput = useCallback(
    (inputValue: string) => {
      if (!wordCount) {
        setFieldValue(name, inputValue);
        return;
      }

      if (wordCount) {
        if (inputValue.length <= wordCount) {
          setFieldValue(name, inputValue);
          return;
        } else {
          if (onPasteRef.current) {
            setFieldValue(name, inputValue.slice(0, wordCount));
            onPasteRef.current = false;
          }
          if (
            keyCodeRef.current &&
            (keyCodeRef.current === 'Backspace' || keyCodeRef.current === 'Delete')
          ) {
            setFieldValue(name, inputValue);
            return;
          }
        }
      }
    },
    [wordCount, setFieldValue, name],
  );

  return (
    <div className="lg:inline-block relative">
      <div
        className={`flex flex-wrap items-start ${wrapperClassname || 'mb-[20px]'} ${
          wordCount && value ? 'pb-[12px]' : ''
        }`}
      >
        <label
          htmlFor={name}
          className={`block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right lg:mt-[8px] shrink-0 ${
            isDisable ? 'text-gray4' : ''
          }`}
        >
          {label}
          {isRequired && <span className="text-red">*</span>}
        </label>
        <div
          className={`flex flex-col w-full lg:w-[600px] ${isUserForm ? styles['user-input'] : ''}`}
        >
          <Field
            name={name}
            placeholder={placeholder || ''}
            className={`${styles['input-field']} ${error && touched ? styles['error'] : ''} ${
              grayPlaceholder ? styles['gray-placeholder'] : ''
            } ${isDisable ? styles['field-disable'] : ''}`}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleChangeInput(event.target.value);
            }}
            onKeyDown={(keyEvent: React.KeyboardEvent) => {
              keyCodeRef.current = keyEvent.code;
            }}
            onPaste={() => {
              onPasteRef.current = true;
            }}
          />
          {hasError && (
            <ErrorMessage
              component="span"
              name={name}
              className={`${styles['error-message']}`}
              render={(errStr) => {
                if (['ssoConnectionName', 'ssoName'].includes(name)) {
                  const errItems = errStr.split('|');
                  return (
                    <ul className={`${styles['error-message']}`}>
                      {errItems.map((e: string, eidx: number) => (
                        <li className="leading-normal text-[12px]" key={eidx}>
                          {e}
                        </li>
                      ))}
                    </ul>
                  );
                } else {
                  return (
                    <span className={`${styles['error-message']} leading-normal text-[12px]`}>
                      {errStr}
                    </span>
                  );
                }
              }}
            />
          )}
        </div>
      </div>
      {wordCount && value && (
        <h5
          className={`absolute top-[45px] lg:top-[45px] xs:top-[74px]  right-0 transition-all leading-normal text-[12px] ${
            error && touched ? 'text-red ' : 'text-gray4'
          }`}
        >
          {WordCount(value)}/{wordCount}
        </h5>
      )}

      {hasTooltip && (
        <ToolTip
          wrapperClassName={`absolute top-[0] right-[14px] lg:top-[10px] lg:right-[-47px] ${tooltipClassname}`}
          tooltipContent={tooltipContent}
          tooltipHeading={tooltipHeading}
        />
      )}
    </div>
  );
};

export default TextInputField;
