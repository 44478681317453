export enum URLS {
  DASHBOARD = "dashboard",
  INSTANCE = "instance",
  WORLD = "world",
  GALLERY = "gallery",
  MESSAGE = "message",
  USER = "user",
  SESSIONS = "session",
  BUILDS = "build",
  ACTIVITIES = "activities",
  GROUP = "group",
}

export enum SORTING_TYPE {
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

export enum GLOBAL_CONST {
  LS_CALLBACK_PAGE = "callbackPage",
}

export const TABLE_PAGINATION = 40;

export enum USER_ROLE {
  USER = 0,
  EVENT_ADMIN = 1,
  GLOBAL_ADMIN = 2,
}

export enum VARIANT_ANNOUNCEMENT {
  ANNOUNCEMENT = "Announcement",
  WARNING = "Warning",
}

export const ANNOUNCEMENT_OPTIONS = [
  {
    id: VARIANT_ANNOUNCEMENT.ANNOUNCEMENT,
    name: VARIANT_ANNOUNCEMENT.ANNOUNCEMENT,
  },
  { id: VARIANT_ANNOUNCEMENT.WARNING, name: VARIANT_ANNOUNCEMENT.WARNING },
];

export enum ADDRESSABLE_TYPES {
  NONE = "None",
  WORLD = "World",
  MEDIA_GALLERY = "MediaGallery",
  PORTAL = "Portal",
  PLINTH = "Plinth",
  ACTIVITY = "Activity",
  MUSIC = "Music",
}

export enum BUILDTARGET_TYPES {
  LOCAL = "Local",
  STAGING1 = "Staging1",
  STAGING2 = "Staging2",
  STAGING3 = "Staging3",
  PRODUCTION = "Production",
}

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const YOUTUBE_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
export const YOUTUBE_PLAYLIST_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:playlist\?list=))(([\w-]+))$/;
export const CURIIOUS_PLATFORM_REGEX =
  /^https:\/\/(events|hub)\.curiious\.com\/.*?$/;
export const HTML_SNIPPET_REGEX = /^<([a-zA-Z][^\s>]*)\s*[^>]*>[\s\S]*<\/\1>$/;
export const IMAGE_REGEX =
  /^(http|https|imagery):\/\/.*\.(jpg|jpeg|png|gif|bmp|webp)$/;
export const INSTANCE_URL_REGEX = /^[a-zA-Z0-9-_]+$/;

export const MAX_FILE_SIZE_IMAGE_UPLOAD = 1 * 1024 * 1024;
export const VISIBLE = ["Hidden", "VIP", "Default"];
export const VISIBLE_OPTIONS = VISIBLE.map((v: string) => {
  // first upper case
  const value = v.charAt(0).toUpperCase() + v.slice(1);
  return {
    id: value,
    name: value,
  };
});
export const GAME_TYPES_ENUM = {
  SimonSays: "SimonSays",
  SimonSaysGame: "SimonSaysGame",
  BasketBall: "BasketBall",
  KeepyUppy: "KeepyUppy",
  KeepUpGame: "KeepUpGame",
  BulletinCarousel: "BulletinCarousel",
  Quiz: "Quiz",
};
export const GAME_TYPES = [
  "SimonSays",
  "BasketBall",
  "KeepyUppy",
  "BulletinCarousel",
  "Quiz",
];
export const GAME_TYPE_OPTIONS = GAME_TYPES.map((v: string) => {
  // first upper case
  const value = v.charAt(0).toUpperCase() + v.slice(1);
  return {
    id: value,
    name: value,
  };
});

export const getGameTypeByAddressable = (addressable: string) => {
  const items = addressable.split("/");
  if (items.length && items[1]) {
    switch (items[1]) {
      case GAME_TYPES_ENUM.KeepyUppy:
      case GAME_TYPES_ENUM.KeepUpGame:
        return GAME_TYPES_ENUM.KeepyUppy;
      case GAME_TYPES_ENUM.SimonSays:
      case GAME_TYPES_ENUM.SimonSaysGame:
        return GAME_TYPES_ENUM.SimonSays;

      case GAME_TYPES_ENUM.BulletinCarousel:
      case GAME_TYPES_ENUM.Quiz:
        return items[1];
      default:
        return GAME_TYPES_ENUM.BasketBall;
    }
  } else {
    return GAME_TYPES_ENUM.BasketBall;
  }
};
// use ref https://github.com/stefangabos/world_countries/blob/master/data/countries/en/countries.json
export const COUNTRIES = [
  { code: "af", name: "Afghanistan" },
  { code: "al", name: "Albania" },
  { code: "dz", name: "Algeria" },
  { code: "ad", name: "Andorra" },
  { code: "ao", name: "Angola" },
  { code: "ag", name: "Antigua and Barbuda" },
  { code: "ar", name: "Argentina" },
  { code: "am", name: "Armenia" },
  { code: "au", name: "Australia" },
  { code: "at", name: "Austria" },
  { code: "az", name: "Azerbaijan" },
  { code: "bs", name: "Bahamas" },
  { code: "bh", name: "Bahrain" },
  { code: "bd", name: "Bangladesh" },
  { code: "bb", name: "Barbados" },
  { code: "by", name: "Belarus" },
  { code: "be", name: "Belgium" },
  { code: "bz", name: "Belize" },
  { code: "bj", name: "Benin" },
  { code: "bt", name: "Bhutan" },
  { code: "bo", name: "Bolivia (Plurinational State of)" },
  { code: "ba", name: "Bosnia and Herzegovina" },
  { code: "bw", name: "Botswana" },
  { code: "br", name: "Brazil" },
  { code: "bn", name: "Brunei Darussalam" },
  { code: "bg", name: "Bulgaria" },
  { code: "bf", name: "Burkina Faso" },
  { code: "bi", name: "Burundi" },
  { code: "cv", name: "Cabo Verde" },
  { code: "kh", name: "Cambodia" },
  { code: "cm", name: "Cameroon" },
  { code: "ca", name: "Canada" },
  { code: "cf", name: "Central African Republic" },
  { code: "td", name: "Chad" },
  { code: "cl", name: "Chile" },
  { code: "cn", name: "China" },
  { code: "co", name: "Colombia" },
  { code: "km", name: "Comoros" },
  { code: "cg", name: "Congo" },
  { code: "cd", name: "Congo, Democratic Republic of the" },
  { code: "cr", name: "Costa Rica" },
  { code: "ci", name: "Côte d'Ivoire" },
  { code: "hr", name: "Croatia" },
  { code: "cu", name: "Cuba" },
  { code: "cy", name: "Cyprus" },
  { code: "cz", name: "Czechia" },
  { code: "dk", name: "Denmark" },
  { code: "dj", name: "Djibouti" },
  { code: "dm", name: "Dominica" },
  { code: "do", name: "Dominican Republic" },
  { code: "ec", name: "Ecuador" },
  { code: "eg", name: "Egypt" },
  { code: "sv", name: "El Salvador" },
  { code: "gq", name: "Equatorial Guinea" },
  { code: "er", name: "Eritrea" },
  { code: "ee", name: "Estonia" },
  { code: "sz", name: "Eswatini" },
  { code: "et", name: "Ethiopia" },
  { code: "fj", name: "Fiji" },
  { code: "fi", name: "Finland" },
  { code: "fr", name: "France" },
  { code: "ga", name: "Gabon" },
  { code: "gm", name: "Gambia" },
  { code: "ge", name: "Georgia" },
  { code: "de", name: "Germany" },
  { code: "gh", name: "Ghana" },
  { code: "gr", name: "Greece" },
  { code: "gd", name: "Grenada" },
  { code: "gt", name: "Guatemala" },
  { code: "gn", name: "Guinea" },
  { code: "gw", name: "Guinea-Bissau" },
  { code: "gy", name: "Guyana" },
  { code: "ht", name: "Haiti" },
  { code: "hn", name: "Honduras" },
  { code: "hu", name: "Hungary" },
  { code: "is", name: "Iceland" },
  { code: "in", name: "India" },
  { code: "id", name: "Indonesia" },
  { code: "ir", name: "Iran (Islamic Republic of)" },
  { code: "iq", name: "Iraq" },
  { code: "ie", name: "Ireland" },
  { code: "il", name: "Israel" },
  { code: "it", name: "Italy" },
  { code: "jm", name: "Jamaica" },
  { code: "jp", name: "Japan" },
  { code: "jo", name: "Jordan" },
  { code: "kz", name: "Kazakhstan" },
  { code: "ke", name: "Kenya" },
  { code: "ki", name: "Kiribati" },
  { code: "kp", name: "Korea (Democratic People's Republic of)" },
  { code: "kr", name: "Korea, Republic of" },
  { code: "kw", name: "Kuwait" },
  { code: "kg", name: "Kyrgyzstan" },
  { code: "la", name: "Lao People's Democratic Republic" },
  { code: "lv", name: "Latvia" },
  { code: "lb", name: "Lebanon" },
  { code: "ls", name: "Lesotho" },
  { code: "lr", name: "Liberia" },
  { code: "ly", name: "Libya" },
  { code: "li", name: "Liechtenstein" },
  { code: "lt", name: "Lithuania" },
  { code: "lu", name: "Luxembourg" },
  { code: "mg", name: "Madagascar" },
  { code: "mw", name: "Malawi" },
  { code: "my", name: "Malaysia" },
  { code: "mv", name: "Maldives" },
  { code: "ml", name: "Mali" },
  { code: "mt", name: "Malta" },
  { code: "mh", name: "Marshall Islands" },
  { code: "mr", name: "Mauritania" },
  { code: "mu", name: "Mauritius" },
  { code: "mx", name: "Mexico" },
  { code: "fm", name: "Micronesia (Federated States of)" },
  { code: "md", name: "Moldova, Republic of" },
  { code: "mc", name: "Monaco" },
  { code: "mn", name: "Mongolia" },
  { code: "me", name: "Montenegro" },
  { code: "ma", name: "Morocco" },
  { code: "mz", name: "Mozambique" },
  { code: "mm", name: "Myanmar" },
  { code: "na", name: "Namibia" },
  { code: "nr", name: "Nauru" },
  { code: "np", name: "Nepal" },
  { code: "nl", name: "Netherlands" },
  { code: "nz", name: "New Zealand" },
  { code: "ni", name: "Nicaragua" },
  { code: "ne", name: "Niger" },
  { code: "ng", name: "Nigeria" },
  { code: "mk", name: "North Macedonia" },
  { code: "no", name: "Norway" },
  { code: "om", name: "Oman" },
  { code: "pk", name: "Pakistan" },
  { code: "pw", name: "Palau" },
  { code: "pa", name: "Panama" },
  { code: "pg", name: "Papua New Guinea" },
  { code: "py", name: "Paraguay" },
  { code: "pe", name: "Peru" },
  { code: "ph", name: "Philippines" },
  { code: "pl", name: "Poland" },
  { code: "pt", name: "Portugal" },
  { code: "qa", name: "Qatar" },
  { code: "ro", name: "Romania" },
  { code: "ru", name: "Russian Federation" },
  { code: "rw", name: "Rwanda" },
  { code: "kn", name: "Saint Kitts and Nevis" },
  { code: "lc", name: "Saint Lucia" },
  { code: "vc", name: "Saint Vincent and the Grenadines" },
  { code: "ws", name: "Samoa" },
  { code: "sm", name: "San Marino" },
  { code: "st", name: "Sao Tome and Principe" },
  { code: "sa", name: "Saudi Arabia" },
  { code: "sn", name: "Senegal" },
  { code: "rs", name: "Serbia" },
  { code: "sc", name: "Seychelles" },
  { code: "sl", name: "Sierra Leone" },
  { code: "sg", name: "Singapore" },
  { code: "sk", name: "Slovakia" },
  { code: "si", name: "Slovenia" },
  { code: "sb", name: "Solomon Islands" },
  { code: "so", name: "Somalia" },
  { code: "za", name: "South Africa" },
  { code: "ss", name: "South Sudan" },
  { code: "es", name: "Spain" },
  { code: "lk", name: "Sri Lanka" },
  { code: "sd", name: "Sudan" },
  { code: "sr", name: "Suriname" },
  { code: "se", name: "Sweden" },
  { code: "ch", name: "Switzerland" },
  { code: "sy", name: "Syrian Arab Republic" },
  { code: "tj", name: "Tajikistan" },
  { code: "tz", name: "Tanzania, United Republic of" },
  { code: "th", name: "Thailand" },
  { code: "tl", name: "Timor-Leste" },
  { code: "tg", name: "Togo" },
  { code: "to", name: "Tonga" },
  { code: "tt", name: "Trinidad and Tobago" },
  { code: "tn", name: "Tunisia" },
  { code: "tr", name: "Türkiye" },
  { code: "tm", name: "Turkmenistan" },
  { code: "tv", name: "Tuvalu" },
  { code: "ug", name: "Uganda" },
  { code: "ua", name: "Ukraine" },
  { code: "ae", name: "United Arab Emirates" },
  { code: "gb", name: "United Kingdom of Great Britain and Northern Ireland" },
  { code: "us", name: "United States of America" },
  { code: "uy", name: "Uruguay" },
  { code: "uz", name: "Uzbekistan" },
  { code: "vu", name: "Vanuatu" },
  { code: "ve", name: "Venezuela (Bolivarian Republic of)" },
  { code: "vn", name: "Viet Nam" },
  { code: "ye", name: "Yemen" },
  { code: "zm", name: "Zambia" },
  { code: "zw", name: "Zimbabwe" },
];

export const COUNTRY_OPTIONS = COUNTRIES.map((v) => {
  return {
    id: v.name,
    name: v.name,
  };
});

export const VALIDATION_MESSAGE = {
  DROP_DOWN: "Please make a selection",
  START_WORLD: "Please select the relevant Start World.",
  URL: "Please ensure URL formatting is valid",
  INSTANCE_URL:
    "Please ensure URL formatting is valid and does not exceed 35 characters",
  EMAIL: "Please ensure email formatting is valid",
  MIN_CHARS: (n: number) => `A minimum of ${n} characters is required`,
  MAX_CHARS: (n: number) => `Maximum characters must be less than ${n}`,
  MESSAGES: (items: string[]) => items,
  CSV: "Upload a valid .csv or text/csv file",
  HTML_SNIPPET: "Please ensure this is valid HTML",
  IMAGE: "Please link to an image file. This must be a direct link",
};
