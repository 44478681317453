import React from 'react';

const Footer = () => {
  return (
    <div className="w-full py-[3.5px] text-center">
      <span className="text-[12px] leading-[28px] text-[#575757]">
        © Curiious {new Date().getFullYear()} all rights reserved.
      </span>
    </div>
  );
};

export default Footer;
