import React from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbPropTypes = {
  mainPageLink: string;
  mainPageLabel: string;
  subPageLabel?: string;
  groupName?: string;
};

const Breadcrumb = ({
  mainPageLink,
  mainPageLabel,
  subPageLabel,
  groupName,
}: BreadcrumbPropTypes) => {
  return (
    <h1 className="text-[20px] leading-[22px] font-[400] mb-[32px] lg:mb-[30px] break-words">
      <Link className="hover:text-soft-blue transition-all" to={mainPageLink}>
        {mainPageLabel.toUpperCase()}
      </Link>
      {groupName && (
        <>
          {' '}
          &gt; <span className="font-[400]">{groupName.toUpperCase()}</span>
        </>
      )}
      {subPageLabel && (
        <>
          {' '}
          &gt; <span className="font-[700]">{subPageLabel.toUpperCase()}</span>
        </>
      )}
    </h1>
  );
};

export default Breadcrumb;
