import useSwr from "swr";
import { AxiosRequestConfig } from "axios";
import type { SWRConfiguration } from "swr";

import axiosInstance from "@/services/apiService";
import useGetAccessToken from "./useGetAccessToken";

const UseFetchData = (
  url?: string,
  config?: AxiosRequestConfig<any>,
  useSWROptions?: SWRConfiguration
) => {
  const token = useGetAccessToken();
  const { data, isLoading, mutate, isValidating, error } = useSwr(
    token && url ? [url, token] : null,
    ([url, token]): Promise<any> => axiosInstance(token).get(url, config),
    { shouldRetryOnError: false, ...useSWROptions }
  );

  return { data, isLoading, mutate, isValidating, error };
};

export default UseFetchData;
