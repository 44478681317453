import React from 'react';

import styles from './CustomField.module.scss';
import CoordinateInput from './CoordinateInput';

type CoordinateFieldPropsType = {
  nameX: string;
  nameY: string;
  nameZ: string;
  valueX: number;
  valueY: number;
  valueZ: number;
  wrapperClassname?: string;
  label: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  error?: { [key: string]: string | undefined };
  touched?: { [key: string]: boolean | undefined };
  isDisable?: boolean;
};

const CoordinateField = ({
  nameX,
  nameY,
  nameZ,
  valueX,
  valueY,
  valueZ,
  wrapperClassname,
  label,
  setFieldValue,
  error,
  touched,
  isDisable,
}: CoordinateFieldPropsType) => {
  return (
    <div className={`flex flex-wrap items-center ${wrapperClassname || 'mb-[20px]'}`}>
      <label
        className={`block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right shrink-0 ${
          isDisable ? 'text-gray4' : ''
        }`}
      >
        {label}
      </label>
      <div
        className={`${styles['coordinate-field-wrapper']} ${
          isDisable ? styles['field-disable'] : ''
        }`}
      >
        <CoordinateInput
          label="X"
          name={nameX}
          setFieldValue={setFieldValue}
          value={valueX}
          error={error && error[nameX]}
          touched={touched && touched[nameX]}
          isDisable={isDisable}
        />
        <CoordinateInput
          label="Y"
          name={nameY}
          setFieldValue={setFieldValue}
          value={valueY}
          error={error && error[nameY]}
          touched={touched && touched[nameY]}
          isDisable={isDisable}
        />
        <CoordinateInput
          label="Z"
          name={nameZ}
          setFieldValue={setFieldValue}
          value={valueZ}
          error={error && error[nameZ]}
          touched={touched && touched[nameZ]}
          isDisable={isDisable}
        />
      </div>
    </div>
  );
};

export default CoordinateField;
