import React from 'react';
import Flatpickr from 'react-flatpickr';
import minMaxTimePlugin from 'flatpickr/dist/plugins/minMaxTimePlugin.js';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

import styles from './CustomField.module.scss';
import { ToolTip } from '../Form';

type DateTimeFieldPropTypes = {
  name: string;
  placeholder?: string;
  label: string;
  error?: string | undefined;
  touched?: boolean | undefined;
  wrapperClassname?: string;
  hasTooltip?: boolean;
  tooltipHeading?: string;
  tooltipContent?: string;
  grayPlaceholder?: boolean;
  isDisable?: boolean;
  tooltipClassname?: string;
  value?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  minDate?: string;
};

const DateTimeField = ({
  label,
  name,
  placeholder,
  error,
  touched,
  wrapperClassname,
  hasTooltip,
  tooltipHeading,
  tooltipContent,
  grayPlaceholder,
  isDisable,
  tooltipClassname,
  value,
  setFieldValue,
  minDate,
}: DateTimeFieldPropTypes) => {
  return (
    <div className="lg:inline-block relative">
      <div className={`flex flex-wrap items-center ${wrapperClassname || 'mb-[20px]'} `}>
        <label
          htmlFor={name}
          className={`block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right shrink-0 ${
            isDisable ? 'text-gray4' : ''
          }`}
        >
          {label}
        </label>
        <Flatpickr
          value={value ? new Date(value) : ''}
          onChange={([date]) => {
            setFieldValue(name, moment(date).format('YYYY-MM-DDTHH:mm:ss'));
          }}
          className={`${styles['input-field']} ${error && touched ? styles['error'] : ''} ${
            grayPlaceholder ? styles['gray-placeholder'] : ''
          } ${isDisable ? styles['field-disable'] : ''}`}
          placeholder={placeholder}
          options={{
            dateFormat: 'Y-d-m H:i',
            minDate: minDate ? moment(minDate).format('YYYY-DD-MM HH:mm') : undefined,
            enableTime: true,
            disableMobile: true,
            plugins: [
              minMaxTimePlugin(
                minDate
                  ? {
                      table: {
                        minDate: {
                          minTime: `${moment(minDate).format('HH:mm')}`,
                        },
                      },
                    }
                  : {},
              ),
            ],
          }}
          name={name}
        />
      </div>
      {hasTooltip && (
        <ToolTip
          wrapperClassName={`absolute top-[0] right-[14px] lg:top-[10px] lg:right-[-47px] ${tooltipClassname}`}
          tooltipContent={tooltipContent}
          tooltipHeading={tooltipHeading}
        />
      )}
    </div>
  );
};

export default React.memo(DateTimeField);
