import React, { FunctionComponent, SVGProps } from "react";

export const DeleteIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M24.9872 11.2017C24.9872 11.2017 24.1727 21.3042 23.7002 25.5597C23.4752 27.5922 22.2197 28.7832 20.1632 28.8207C16.2497 28.8912 12.3317 28.8957 8.41974 28.8132C6.44124 28.7727 5.20674 27.5667 4.98624 25.5702C4.51074 21.2772 3.70074 11.2017 3.70074 11.2017"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M24.9872 11.2017C24.9872 11.2017 24.1727 21.3042 23.7002 25.5597C23.4752 27.5922 22.2197 28.7832 20.1632 28.8207C16.2497 28.8912 12.3317 28.8957 8.41974 28.8132C6.44124 28.7727 5.20674 27.5667 4.98624 25.5702C4.51074 21.2772 3.70074 11.2017 3.70074 11.2017"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M24.9872 11.2017C24.9872 11.2017 24.1727 21.3042 23.7002 25.5597C23.4752 27.5922 22.2197 28.7832 20.1632 28.8207C16.2497 28.8912 12.3317 28.8957 8.41974 28.8132C6.44124 28.7727 5.20674 27.5667 4.98624 25.5702C4.51074 21.2772 3.70074 11.2017 3.70074 11.2017"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M24.9872 11.2017C24.9872 11.2017 24.1727 21.3042 23.7002 25.5597C23.4752 27.5922 22.2197 28.7832 20.1632 28.8207C16.2497 28.8912 12.3317 28.8957 8.41974 28.8132C6.44124 28.7727 5.20674 27.5667 4.98624 25.5702C4.51074 21.2772 3.70074 11.2017 3.70074 11.2017"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M24.9872 11.2017C24.9872 11.2017 24.1727 21.3042 23.7002 25.5597C23.4752 27.5922 22.2197 28.7832 20.1632 28.8207C16.2497 28.8912 12.3317 28.8957 8.41974 28.8132C6.44124 28.7727 5.20674 27.5667 4.98624 25.5702C4.51074 21.2772 3.70074 11.2017 3.70074 11.2017"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M24.9872 11.2017C24.9872 11.2017 24.1727 21.3042 23.7002 25.5597C23.4752 27.5922 22.2197 28.7832 20.1632 28.8207C16.2497 28.8912 12.3317 28.8957 8.41974 28.8132C6.44124 28.7727 5.20674 27.5667 4.98624 25.5702C4.51074 21.2772 3.70074 11.2017 3.70074 11.2017"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0622 6.35889H1.62518"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1608 6.35923C20.9833 6.35923 19.9693 5.52673 19.7383 4.37323L19.3738 2.54923C19.1488 1.70773 18.3868 1.12573 17.5183 1.12573H11.1688C10.3003 1.12573 9.53834 1.70773 9.31334 2.54923L8.94884 4.37323C8.71784 5.52673 7.70384 6.35923 6.52634 6.35923"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
