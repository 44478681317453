import React from 'react';

import styles from './Table.module.scss';
import PlayIcon from '@icon/PlayIcon';
import EditIcon from '@/icons/EditIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import LogoExample from '@image/logo-example.png';
import Container from '../Container';
import ColorSquare from './ColorSquare';
import ClickableHeading from './ClickableHeading';
import { SORTING_TYPE } from '@/utils/constants';

const InstancesTable = () => {
  return (
    <Container wrapperClassName="!px-[0] md:!px-[0] w-full" extraContainerClassName="bg-gray">
      <div className="hidden md:block overflow-auto ml-[22px] pt-[31px] pb-[29px] mr-[43px]">
        <div className="min-w-[1000px]">
          <table className={styles['table']}>
            <colgroup>
              <col style={{ width: '66px' }} />
              <col span={2} />
              <col span={4} />
              <col />
              <col />
              <col />
              <col />
              <col style={{ width: '160px' }} />
            </colgroup>
            <thead>
              <tr className="border-[3px] border-solid border-[transparent] border-b-[#000]">
                <th className="text-left">
                  <ClickableHeading
                    title="ID"
                    action={() => undefined}
                    spacing={11}
                    orderState={SORTING_TYPE.ASCENDING}
                  />
                </th>
                <th colSpan={2} className="text-left">
                  <ClickableHeading
                    title="Name"
                    action={() => undefined}
                    spacing={11}
                    orderState={SORTING_TYPE.ASCENDING}
                  />
                </th>
                <th colSpan={4} className="text-left">
                  Description
                </th>
                <th className={`${styles['no-upper']} text-left`}>1st</th>
                <th className={`${styles['no-upper']} text-left`}>2nd</th>
                <th className="text-left">LOGO</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles['data-row']}>
                <td className={`pt-[12px] pb-[9px] align-top`}>1</td>
                <td colSpan={2} className="pt-[12px] pb-[9px]">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, quod?</p>
                </td>
                <td colSpan={4} className="pt-[12px] pb-[9px]">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio ut explicabo
                    mollitia aut quae architecto maiores repellat minima doloremque at!.
                  </p>
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <ColorSquare color="#AD0B9D" />
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <ColorSquare color="#0B4CAD" />
                </td>
                <td className="pt-[12px] pb-[9px] image">
                  <img src={LogoExample} alt="Example logo" />
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <div className="flex justify-center">
                    <div className="cursor-pointer">
                      <PlayIcon className="stroke" />
                    </div>
                  </div>
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <div className="flex justify-end">
                    <div className="cursor-pointer mr-[42px]">
                      <EditIcon />
                    </div>
                    <div className="cursor-pointer">
                      <DeleteIcon className="stroke" />
                    </div>
                  </div>
                </td>
              </tr>
              <tr className={styles['data-row']}>
                <td className={`pt-[12px] pb-[9px] align-top`}>1</td>
                <td colSpan={2} className="pt-[12px] pb-[9px]">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, quod?</p>
                </td>
                <td colSpan={4} className="pt-[12px] pb-[9px]">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio ut explicabo
                    mollitia aut quae architecto maiores repellat minima doloremque at!.
                  </p>
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <ColorSquare color="#AD0B9D" />
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <ColorSquare color="#0B4CAD" />
                </td>
                <td className="pt-[12px] pb-[9px] image">
                  <img src={LogoExample} alt="Example logo" />
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <div className="flex justify-center">
                    <div className="cursor-pointer">
                      <PlayIcon className="stroke" />
                    </div>
                  </div>
                </td>
                <td className="pt-[12px] pb-[9px] icon">
                  <div className="flex justify-end">
                    <div className="cursor-pointer mr-[42px]">
                      <EditIcon />
                    </div>
                    <div className="cursor-pointer">
                      <DeleteIcon className="stroke" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="block md:hidden py-[14px] px-[10px]">
        <div className={styles['table-col-sm']}>
          <div className={styles['col-sm']}>
            <div className={styles['title']}>ID</div>
            <div className={styles['content']}>1</div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['title']}>NAME</div>
            <div className={styles['content']}>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['title']}>DESCR&shy;IPTION</div>
            <div className={styles['content']}>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, excepturi voluptatum
                asperiores aperiam culpa sit minima et corporis hic quas.
              </p>
            </div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['color']}>
              <div className={styles['title']}>1st</div>
              <div className={styles['content']}>
                <ColorSquare color="#AD0B9D" />
              </div>
            </div>
            <div className={styles['color']}>
              <div className={styles['title']}>2nd</div>
              <div className={styles['content']}>
                <ColorSquare color="#0B4CAD" />
              </div>
            </div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['logo']}>
              <img src={LogoExample} alt="Example logo" />
            </div>
            <div className={styles['buttons']}>
              <PlayIcon className="stroke" />
              <EditIcon width={36} />
              <DeleteIcon width={36} className="stroke" />
            </div>
          </div>
        </div>
        <div className={styles['table-col-sm']}>
          <div className={styles['col-sm']}>
            <div className={styles['title']}>ID</div>
            <div className={styles['content']}>1</div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['title']}>NAME</div>
            <div className={styles['content']}>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['title']}>DESCR&shy;IPTION</div>
            <div className={styles['content']}>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, excepturi voluptatum
                asperiores aperiam culpa sit minima et corporis hic quas.
              </p>
            </div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['color']}>
              <div className={styles['title']}>1st</div>
              <div className={styles['content']}>
                <ColorSquare color="#AD0B9D" />
              </div>
            </div>
            <div className={styles['color']}>
              <div className={styles['title']}>2nd</div>
              <div className={styles['content']}>
                <ColorSquare color="#0B4CAD" />
              </div>
            </div>
          </div>
          <div className={styles['col-sm']}>
            <div className={styles['logo']}>
              <img src={LogoExample} alt="Example logo" />
            </div>
            <div className={styles['buttons']}>
              <PlayIcon className="stroke" />
              <EditIcon width={36} />
              <DeleteIcon width={36} className="stroke" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InstancesTable;
