import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ErrorMessage, Field } from 'formik';

import { sortOptions } from '@/utils/common';
import useClickOutside from '@/custom-hooks/useClickOutside';

import styles from './CustomField.module.scss';
import ArrowDownIcon from '@/icons/ArrowDownIcon';

type DropdownFieldProps = {
  name: string;
  label: string;
  isRequired?: boolean;
  options: OptionsType;
  value: any;
  wrapperClassname?: string;
  dropText?: string;
  noPaddingTopLabel?: boolean;
  isDisable?: boolean;
  error?: string | undefined;
  touched?: boolean | undefined;
  disableSort?: boolean;
  clickOutside?: () => void;
  customClassName?: string;
  isUserForm?: boolean;
};

type OptionsType = { id: any; name: any }[];

const DropdownField = ({
  label,
  name,
  isRequired,
  options,
  value,
  wrapperClassname,
  dropText,
  noPaddingTopLabel,
  isDisable,
  error,
  touched,
  disableSort,
  clickOutside,
  customClassName,
  isUserForm,
}: DropdownFieldProps) => {
  const [currentValue, setCurrentValue] = useState<string>('');
  const [isOpenningDropdown, setIsOpenningDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const fieldRef = useRef<HTMLDivElement>(null);
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const [dropdownHeight, setDrropdownHeight] = useState(0);
  const sortedOptions: OptionsType = useMemo(() => {
    if (disableSort) return options;

    return sortOptions(options, 'name');
  }, [options, disableSort]);

  useClickOutside(fieldRef, (e) => {
    if (
      fieldRef.current &&
      dropdownWrapperRef.current &&
      !fieldRef.current.contains(e.target) &&
      !dropdownWrapperRef.current?.contains(e.target) &&
      isOpenningDropdown
    ) {
      setIsOpenningDropdown(false);
      if (clickOutside) {
        clickOutside();
      }
    }
  });

  useEffect(() => {
    if (value) {
      const curOpt = sortedOptions.find((opt) => opt.id.toString() === value?.toString());
      if (curOpt) setCurrentValue(curOpt.name);
    }
  }, [value, sortedOptions]);

  useEffect(() => {
    if (dropdownRef.current) {
      setDrropdownHeight(dropdownRef.current.clientHeight);
    }
  }, [sortedOptions]);

  return (
    <div className={`flex flex-wrap items-start ${wrapperClassname || 'mb-[20px]'}`}>
      <label
        htmlFor={name}
        className={`block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right ${
          noPaddingTopLabel ? '' : 'lg:mt-[8px]'
        } shrink-0 ${isDisable ? 'text-gray4' : ''}`}
      >
        {label}
        {isRequired && <span className="text-red">*</span>}
      </label>
      <div
        className={`flex flex-col w-full lg:w-[600px] ${isUserForm ? styles['user-input'] : ''}`}
      >
        <div
          tabIndex={0}
          className={`${styles['dropdown-field']} ${isDisable ? styles['field-disable'] : ''} ${
            error && touched ? styles['error'] : ''
          } ${customClassName ? styles[customClassName] : ''}`}
        >
          <div
            ref={fieldRef}
            className={`${styles['dropdown-cur-value']} break-words`}
            onClick={() => {
              if (!isDisable) setIsOpenningDropdown(!isOpenningDropdown);
            }}
          >
            {value ? currentValue : dropText || '-- Drop down to select'}
            <div className={`${styles['arrow']} ${isOpenningDropdown && styles['arrow--up']}`}>
              <ArrowDownIcon className="no-hover" />
            </div>
          </div>
          <div
            ref={dropdownWrapperRef}
            className={styles['dropdown-wrapper']}
            style={{
              maxHeight: `${dropdownHeight && isOpenningDropdown ? dropdownHeight : 0}px`,
            }}
          >
            <div ref={dropdownRef} className={`${styles['dropdown']} radio-group`}>
              {sortedOptions.length > 0 && (
                <>
                  {sortedOptions.map((opt: any, idx: number) => (
                    <label
                      className={`block text-[16px] leading-[24px] cursor-pointer py-[5px] pl-[10px] pr-[32px] md:pl-[19px] break-words ${
                        value?.toString() === String(opt.id)
                          ? `bg-gray3 ${styles['is-selected']}`
                          : ''
                      }`}
                      key={idx}
                    >
                      <Field
                        name={name}
                        className={`${styles['input-field']} ${styles['dropdown-select']}`}
                        type="radio"
                        value={opt.id}
                        onClick={() => setIsOpenningDropdown(!isOpenningDropdown)}
                      />
                      <span className={styles['select-text']}>{opt.name}</span>
                    </label>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <ErrorMessage component="span" name={name} className={`${styles['error-message']}`} />
      </div>
    </div>
  );
};

export default React.memo(DropdownField);
