import React, { FunctionComponent, SVGProps } from "react";

export const ClearIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99988 14L14 2"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14 14L1.99988 2"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ClearIcon;
