import { atom } from "recoil";

export const userRoleState = atom({
  key: "userRoleState",
  default: 0,
});
export const userEnrolledInstancesState = atom({
  key: "userEnrolledInstancesState",
  default: [] as number[],
});
export const userEnrolledSessionsState = atom({
  key: "userEnrolledSessionsState",
  default: [] as number[],
});
