import { hasIn, orderBy } from "lodash";

export function WordCount(str: string) {
  return str.replace(/(\r\n|\n|\r)/gm, "").length;
}

export function checkExceedWordCount(str: string, max: number) {
  return Boolean(WordCount(str) <= max);
}

export function checkValidURL(str: string) {
  let url: URL;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function generateSasUri(refUri: string) {
  const isValidURL = checkValidURL(refUri);

  if (!isValidURL) {
    const doubleEncodedURL = encodeURIComponent(encodeURIComponent(refUri));

    return { encoded: true, url: doubleEncodedURL };
  } else return { encoded: false, url: refUri };
}

export function sortOptions(
  options: Array<any>,
  sortBy: string,
  selectedFirst?: Array<any>
) {
  const orderFn = (options: Array<any>, sortBy: string) => {
    return orderBy(
      options,
      [
        (options) => {
          if (typeof options[sortBy] === "number") {
            return options[sortBy];
          }

          return options[sortBy]?.toLowerCase();
        },
      ],
      "asc"
    );
  };
  if (selectedFirst && selectedFirst.length) {
    const selected = options.filter((o) =>
      isNaN(o.id) ? selectedFirst.includes(o.id) : selectedFirst.includes(+o.id)
    );
    const nonSelected = options.filter((o) =>
      isNaN(o.id)
        ? !selectedFirst.includes(o.id)
        : !selectedFirst.includes(+o.id)
    );
    const orderBySelected = orderFn(selected, sortBy);
    const orderByNonSelected = orderFn(nonSelected, sortBy);

    return [...orderBySelected, ...orderByNonSelected];
  } else {
    return orderFn(options, sortBy);
  }
}

export function scrollToError(errors: any, keys: string[]) {
  if (errors && keys && Object.values(errors).length) {
    for (const field of keys) {
      if (hasIn(errors, field)) {
        const fieldElement = document.getElementsByName(field)[0];
        if (fieldElement) {
          const style = window.getComputedStyle(fieldElement);
          const attrType = fieldElement.getAttribute("type");
          const parentElement = fieldElement.closest(".radio-group");
          if (parentElement) {
            parentElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            break;
          } else {
            const display = style.getPropertyValue("display");
            const parent = fieldElement?.parentElement?.parentElement;
            const multipleSelect = parent?.classList?.value.includes(
              "multi-select-wrapper"
            );

            // multiple select element
            if (
              (display === "none" && multipleSelect && parent) ||
              (display === "none" && attrType === "file" && parent) ||
              (parent && parent?.classList.value.includes("error"))
            ) {
              parent.scrollIntoView({ behavior: "smooth", block: "center" });
              break;
            } else {
              fieldElement.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
              break;
            }
          }
        }
      }
    }
  }
}
