import React, { ReactNode } from 'react';
import { RecoilState, useRecoilValue } from 'recoil';

import useScreen from '@/custom-hooks/useScreen';

import Layout from './Layout';
import Container from '../Container';
import Button from '../Button';
import SearchBox from '../SearchBox';
import LoadingOverlay from '../LoadingOverlay';
import useCheckUserRole from '@/custom-hooks/useCheckUserRole';
import { USER_ROLE } from '@/utils/constants';
import { Breadcrumb } from '../Form';

type ListPageLayoutPropsType = {
  data: Array<any> | undefined;
  isLoading: boolean;
  children: ReactNode;
  pageHeading:
    | string
    | {
        mainPageLabel: string;
        mainPageLink: string;
        subPageLabel?: string;
      };
  pageDescription: string;
  createNewLink: string;
  searchState: RecoilState<string>;
  handleSearch: (key: string) => void;
  handleClear: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isHideSearchBox?: boolean | undefined;
  isCheckRoleToCreateNew?: boolean;
};

const ListPageLayout = ({
  data,
  isLoading,
  children,
  pageHeading,
  pageDescription,
  createNewLink,
  searchState,
  handleSearch,
  handleClear,
  isHideSearchBox,
  isCheckRoleToCreateNew = true,
}: ListPageLayoutPropsType) => {
  const screen = useScreen();
  const role = useCheckUserRole();
  const searchValue = useRecoilValue(searchState);

  const PageHeading = ({
    pageHeading,
  }: {
    pageHeading:
      | string
      | {
          mainPageLabel: string;
          mainPageLink: string;
          subPageLabel?: string;
        };
  }) => {
    if (typeof pageHeading === 'object') {
      const { mainPageLabel, mainPageLink, subPageLabel } = pageHeading;
      return (
        <Breadcrumb
          mainPageLabel={mainPageLabel}
          mainPageLink={mainPageLink}
          subPageLabel={subPageLabel}
        />
      );
    } else {
      return <h1 className="text-[20px] leading-[22px] font-[400] xl:font-[700]">{pageHeading}</h1>;
    }
  };

  return (
    <Layout isFullScreen>
      <div className="pt-[20px] xl:pt-[43px] pb-[15px] flex-1 flex flex-col">
        <Container extraContainerClassName="md:pl-[24px]">
          <PageHeading pageHeading={pageHeading} />
          {(pageDescription || createNewLink || !isHideSearchBox) && (
            <div className="flex flex-col xl:flex-row xl:justify-between mb-[26px] xl:mb-[29px] mt-[14px] xl:mt-[17px]">
              {pageDescription && (
                <p className="lg:w-[526px] text-[16px] leading-[21px] mb-[15px] xl:mb-0 xl:mr-[8px]">
                  {pageDescription}
                </p>
              )}
              <div className="flex flex-col md:flex-row">
                {createNewLink && (
                  <div className="mb-[16px] md:mb-0 md:mr-[28px]">
                    <Button
                      size={screen.isTablet || screen.isDesktop ? 'default' : 'large'}
                      type="link"
                      color="black"
                      label="Create new"
                      href={createNewLink}
                      disable={isCheckRoleToCreateNew && role.userRole !== USER_ROLE.GLOBAL_ADMIN}
                    />
                  </div>
                )}
                {!isHideSearchBox && (
                  <div className="w-[268px]">
                    <SearchBox
                      searchState={searchState}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
        <Container
          wrapperClassName="flex-1 flex flex-col !px-0 md:!px-[50px]"
          extraContainerClassName="flex-1 bg-gray"
        >
          {data && (
            <>
              {data.length > 0 ? (
                <>{children}</>
              ) : searchValue && searchValue.length >= 3 ? (
                <h4 className="pt-[24px] pb-[24px] pl-[15px] md:pl-[24px]">
                  There is no results found. Please search again
                </h4>
              ) : (
                ''
              )}
            </>
          )}
        </Container>
      </div>
      {(isLoading || role.isUserDataLoading) && <LoadingOverlay />}
    </Layout>
  );
};

export default React.memo(ListPageLayout);
