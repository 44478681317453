import React, { useRef } from 'react';
import { useRecoilState } from 'recoil';

import { deletePopupState } from '../../recoil/atom/popup';

import styles from './Popup.module.scss';
import Button from '../Button';
import Overlay from '../Overlay';
import CloseSquareIcon from '@/icons/CloseSquareIcon';
import useClickOutside from '@/custom-hooks/useClickOutside';

type DeletePopupPropsType = {
  action?: () => void;
};

const DeletePopup = ({ action }: DeletePopupPropsType) => {
  const [isShow, setIsShow] = useRecoilState(deletePopupState);
  const popupWrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(popupWrapperRef, () => {
    if (isShow) {
      setIsShow(false);
    }
  });

  return (
    <Overlay isShow={isShow}>
      <div ref={popupWrapperRef} className={styles['popup-wrapper']}>
        <div className={styles['close-btn']} onClick={() => setIsShow(false)}>
          <CloseSquareIcon width={19} height={19} className="stroke" />
        </div>
        <h2 className={styles['title']}>Delete?</h2>
        <p className={styles['description']}>
          Are you sure you want to delete this item? All changes are permanent and data cannot be
          retrieved.
        </p>
        <div className={styles['btn-wrapper']}>
          <div className={styles['btn']}>
            <Button
              color="soft-blue"
              label="DELETE PERMANENTLY "
              action={() => {
                setIsShow(false);
                action && action();
              }}
            />
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default DeletePopup;
