import { RefObject, useEffect } from "react";

export default function useClickOutside(
  ref: RefObject<HTMLDivElement>,
  action: (event?: any) => void,
  dependency?: boolean
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(event);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, dependency, action]);
}
