import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { showDesktopNavState } from '@/recoil/atom/header';

import styles from './Layout.module.scss';
import Header from '../Header';
import Footer from '../Footer';

type LayoutPropsType = {
  children: ReactNode;
  wrapperClassName?: string;
  isFullScreen?: boolean;
  hasFooter?: boolean;
};

const Layout = ({
  children,
  wrapperClassName,
  isFullScreen,
  hasFooter = false,
}: LayoutPropsType) => {
  const isShowingDesktopNav = useRecoilValue(showDesktopNavState);

  return (
    <div className={`${isFullScreen ? 'min-h-[100vh] flex flex-col' : ''} `}>
      <Header />
      <div
        className={`${styles['content-wrapper']} relative  ml-auto transition-all ${
          isFullScreen ? 'flex-1' : ''
        } ${isShowingDesktopNav ? 'w-[calc(100%-215px)]' : 'w-full'}`}
      >
        <div className={`${wrapperClassName} ${isFullScreen ? 'flex flex-col' : ''}`}>
          {children}
        </div>
        {hasFooter && <Footer />}
      </div>
    </div>
  );
};

export default React.memo(Layout);
