import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Form.module.scss';

type FormLinkPropsType = {
  wrapperClassname?: string;
  linkLabel: string;
  to?: string;
};

const FormLink = ({ wrapperClassname, linkLabel, to }: FormLinkPropsType) => {
  return (
    <div className={`lg:pl-[191px] ${wrapperClassname || 'mb-[20px]'}`}>
      {to && (
        <Link to={to} className={styles['form-link']}>
          {linkLabel}
        </Link>
      )}
    </div>
  );
};

export default FormLink;
