import { atom } from "recoil";

export const showDesktopNavState = atom({
  key: "showDesktopNavState",
  default: false,
});

export const showMobileNavState = atom({
  key: "showMobileNavState",
  default: false,
});
