import React, { FunctionComponent, SVGProps } from "react";

export const TickSquareIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4975 2.25C4.7025 2.25 2.25 4.8495 2.25 8.874V21.126C2.25 25.1505 4.7025 27.75 8.4975 27.75H21.4995C25.296 27.75 27.75 25.1505 27.75 21.126V8.874C27.75 4.8495 25.296 2.25 21.501 2.25H8.4975ZM21.4995 30H8.4975C3.414 30 0 26.433 0 21.126V8.874C0 3.567 3.414 0 8.4975 0H21.501C26.5845 0 30 3.567 30 8.874V21.126C30 26.433 26.5845 30 21.4995 30Z"
        fill={"black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2203 19.6845C12.9338 19.6845 12.6443 19.575 12.4253 19.3545L8.86429 15.795C8.42479 15.3555 8.42479 14.6445 8.86429 14.205C9.30379 13.7655 10.0148 13.7655 10.4543 14.205L13.2203 16.968L19.5443 10.6455C19.9838 10.206 20.6948 10.206 21.1343 10.6455C21.5738 11.085 21.5738 11.796 21.1343 12.2355L14.0153 19.3545C13.7963 19.575 13.5083 19.6845 13.2203 19.6845Z"
        fill={props.fill || "black"}
      />
    </svg>
  );
};

export default TickSquareIcon;
