import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilState } from "recoil";

import useGetAccessToken from "./useGetAccessToken";
import {
  userEnrolledInstancesState,
  userEnrolledSessionsState,
  userRoleState,
} from "@/recoil/atom/userRole";
import UseFetchData from "./useFetchData";
import { UserType } from "@/types/user";

const useCheckUserRole = () => {
  const { user } = useAuth0();
  const token = useGetAccessToken();
  const [userRole, setUserRole] = useRecoilState(userRoleState);
  const [userEnrolledInstances, setUserEnrolledInstances] = useRecoilState(
    userEnrolledInstancesState
  );
  const [userEnrolledSessions, setUserEnrolledSessions] = useRecoilState(
    userEnrolledSessionsState
  );
  const { data: userData, isLoading: isUserDataLoading } = UseFetchData(
    token && user && user.sub ? `Users/UUID/${user.sub}` : undefined
  );

  useEffect(() => {
    if (userData) {
      setUserRole((userData as UserType).role);
      if (userData.enrolledInstances && userData.enrolledInstances.length) {
        setUserEnrolledInstances(userData.enrolledInstances);
        setUserEnrolledSessions(userData.enrolledSessions);
      }
    }
  }, [
    userData,
    setUserRole,
    setUserEnrolledInstances,
    setUserEnrolledSessions,
  ]);

  return {
    userRole,
    isUserDataLoading,
    userData,
    userEnrolledInstances,
    userEnrolledSessions,
  };
};

export default useCheckUserRole;
