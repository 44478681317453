import React, { useMemo, useRef } from 'react';
import { Field } from 'formik';

import styles from './CustomField.module.scss';
import { sortOptions } from '@/utils/common';

type MutliSelectPropsType = {
  name: string;
  label: string;
  isRequired?: boolean;
  options: OptionsType;
  value: string[];
  wrapperClassname?: string;
  error?: string | undefined;
  touched?: boolean | undefined;
  customClassName?: string;
  onChange?: (item: { id: number; checked: boolean }) => void;
  selectedFirst?: string[] | number[];
};

type OptionsType = { id: any; name: any }[];

const MultiSelectField = ({
  name,
  label,
  isRequired,
  options,
  value,
  wrapperClassname,
  error,
  touched,
  customClassName,
  onChange,
  selectedFirst,
}: MutliSelectPropsType) => {
  const ref = useRef(null);
  const sortedOptions: OptionsType = useMemo(() => {
    return sortOptions(options, 'name', selectedFirst);
  }, [options, selectedFirst]);

  return (
    <div className={`flex flex-wrap items-start ${wrapperClassname || 'mb-[20px]'}`}>
      <label
        htmlFor={name}
        className="block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right lg:mt-[8px] shrink-0"
      >
        {label}
        {isRequired && <span className="text-red">*</span>}
      </label>
      <div
        ref={ref}
        tabIndex={0}
        className={`${styles['multi-select-wrapper']} ${error && touched ? styles['error'] : ''} ${
          customClassName ? styles[customClassName] : ''
        }`}
      >
        {sortedOptions.length > 0 && (
          <>
            {sortedOptions.map((opt, idx) => (
              <label
                className={`cursor-pointer block text-[16px] leading-[24px] py-[5px] pl-[10px] pr-[32px] md:pl-[19px] md:pr-[66px] transition-all ${
                  value.length && value.includes(opt.id.toString())
                    ? `bg-gray3 ${styles['is-item-active']} item-active`
                    : ''
                }`}
                key={idx}
                onClick={() => {
                  if (onChange) {
                    if (value.includes(opt.id.toString())) {
                      onChange({ id: opt.id, checked: false });
                    } else {
                      onChange({ id: opt.id, checked: true });
                    }
                  }
                }}
              >
                <Field
                  name={name}
                  className={`${styles['input-field']} ${styles['multi-select']}`}
                  type="checkbox"
                  value={opt.id}
                />
                <span className={`${styles['select-text']}`}>{opt.name}</span>
              </label>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MultiSelectField;
