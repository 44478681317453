import React, { FunctionComponent, SVGProps } from "react";

export const HamburgerIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2.45117"
        y1="2"
        x2="21.4512"
        y2="2"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="2.45117"
        y1="9"
        x2="21.4512"
        y2="9"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="2.45117"
        y1="16"
        x2="21.4512"
        y2="16"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HamburgerIcon;
