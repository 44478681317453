import React from 'react';
import parse from 'html-react-parser';

import styles from './Popup.module.scss';
import Triangle from '@image/triangle.png';

type ToolTipPopupPropsType = {
  heading?: string;
  content?: string;
};

const ToolTipPopup = ({ heading, content }: ToolTipPopupPropsType) => {
  return (
    <>
      <div className={`${styles['tooltip-box']}`}>
        <div className="hidden xl:block absolute w-[16px] h-[30px] top-[50%] left-[-16px] translate-y-[-50%]">
          <img src={Triangle} alt="" className="w-[100%] h-[100%]" />
        </div>
        <div className="block xl:hidden absolute w-[16px] h-[30px] right-[13px] bottom-[-23px] rotate-[-90deg]">
          <img src={Triangle} alt="" className="w-[100%] h-[100%]" />
        </div>
        {heading && <h5 className="text-[12px] leading-[20px] font-[500]">{parse(heading)}</h5>}
        {content && (
          <p className="text-[12px] leading-[20px] font-[400] w-[210px]">{parse(content)}</p>
        )}
      </div>
    </>
  );
};

export default ToolTipPopup;
