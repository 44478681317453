import React, { ReactNode } from 'react';

type OverlayPropsType = {
  children?: ReactNode;
  isShow: boolean;
};

const Overlay = ({ children, isShow }: OverlayPropsType) => {
  if (!isShow) return null;

  return (
    <div
      className="fixed inset-0 z-[10001]"
      style={{ backgroundImage: 'linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2))' }}
    >
      {children}
    </div>
  );
};

export default Overlay;
