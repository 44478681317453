import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Popup.module.scss';
import Overlay from '../Overlay';
import Button from '../Button';
import CloseSquareIcon from '@/icons/CloseSquareIcon';

type LoginPopupPropsType = {
  isShow: boolean;
};

const LoginPopup = ({ isShow }: LoginPopupPropsType) => {
  const navigate = useNavigate();
  const [isShowPopup, setIsShowPopup] = useState(isShow);

  const handleClosePopup = () => {
    setIsShowPopup(false);
    navigate('/login');
  };

  return (
    <Overlay isShow={isShowPopup}>
      <div className={styles['popup-wrapper']}>
        <div className={styles['close-btn']} onClick={handleClosePopup}>
          <CloseSquareIcon width={19} height={19} className="stroke" />
        </div>
        <h2 className={styles['title']}>Error!</h2>
        <p className={`${styles['description']} text-center`}>Login failed!</p>
        <div className={styles['btn-wrapper']}>
          <div className={styles['btn']}>
            <Button color="soft-blue" label="OK" action={handleClosePopup} />
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default LoginPopup;
