import { useState, useEffect, useMemo } from "react";

import { generateSasUri } from "@/utils/common";
import UseFetchData from "./useFetchData";

const useGenerateSasUri = (str: string | undefined, regenerate?: boolean) => {
  const generatedUrl = useMemo(() => {
    if (str || regenerate) return generateSasUri(str as string);

    return undefined;
  }, [str, regenerate]);
  const [url, setUrl] = useState("");
  const {
    data: generatedData,
    isLoading: isRegenerating,
    mutate,
  } = UseFetchData(
    generatedUrl && generatedUrl.encoded && generatedUrl.url
      ? `/Utility/GenerateSas/${generatedUrl.url}`
      : undefined,
    undefined,
    { shouldRetryOnError: false }
  );

  useEffect(() => {
    if (regenerate) {
      mutate();
    }
  }, [mutate, regenerate]);

  useEffect(() => {
    if (generatedUrl && !generatedUrl.encoded) {
      setUrl(generatedUrl.url);
    }
  }, [generatedUrl, regenerate]);

  useEffect(() => {
    if (generatedData) {
      setUrl(generatedData);
    }
  }, [generatedData, regenerate]);

  return { url, isRegenerating };
};

export default useGenerateSasUri;
