import React, { useMemo } from 'react';

import Button from './Button';
import { WorldType } from '@/types/world';
import { InstanceType } from '@/types/instance';

type ViewInWordButtonPropsType = {
  worldId: string;
  worldsData: WorldType[] | undefined;
  instancesData: InstanceType[] | undefined;
};

const ViewInWorldButton = ({ worldId, instancesData, worldsData }: ViewInWordButtonPropsType) => {
  const instanceName = useMemo(() => {
    if (worldId && worldsData) {
      const selectedWorld = worldsData.find((item) => item.id.toString() === worldId);

      if (selectedWorld && instancesData && instancesData.length) {
        const selectedInstance = instancesData.find((item) => item.id === selectedWorld.instance);

        return selectedInstance ? selectedInstance.displayName : '';
      }

      return '';
    }

    return '';
  }, [worldId, instancesData, worldsData]);
  const viewWorldURL = useMemo(() => {
    if (instanceName && worldId)
      return `https://events.curiious.com/${instanceName}/world/${worldId}`;

    return '';
  }, [instanceName, worldId]);

  return (
    <Button
      type="link"
      color="soft-blue"
      label="View In World"
      href={viewWorldURL}
      disable={!viewWorldURL}
      target="_blank"
      externalLink
      hasArrowIcon
    ></Button>
  );
};

export default React.memo(ViewInWorldButton);
