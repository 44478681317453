import React, { useRef } from 'react';
import { useRecoilState } from 'recoil';

import { fileErrorPopupState } from '../../recoil/atom/popup';

import styles from './Popup.module.scss';
import Button from '../Button';
import Overlay from '../Overlay';
import CloseSquareIcon from '@/icons/CloseSquareIcon';
import useClickOutside from '@/custom-hooks/useClickOutside';
import { Link } from 'react-router-dom';

const FileErrorPopup = () => {
  const [isShow, setIsShow] = useRecoilState(fileErrorPopupState);
  const popupWrapperRef = useRef<HTMLDivElement>(null);
  const encodedEmail = encodeURIComponent('help@curiious.com');

  useClickOutside(popupWrapperRef, () => {
    if (isShow) {
      setIsShow(false);
    }
  });

  return (
    <Overlay isShow={isShow}>
      <div ref={popupWrapperRef} className={styles['popup-wrapper']}>
        <div className={styles['close-btn']} onClick={() => setIsShow(false)}>
          <CloseSquareIcon width={19} height={19} className="stroke" />
        </div>
        <h2 className={styles['title']}>File error!</h2>
        <p className={styles['description']}>
          <p>
            The file you have uploaded does not meet the specifications required. Please review the
            instructions below the field and try again.
          </p>
          <p className="mt-3">
            For assistance contact us at{' '}
            <Link to={`mailto:${encodedEmail}`} className="text-bright-blue">
              help@curiious.com
            </Link>
          </p>
        </p>
        <div className={styles['btn-wrapper']}>
          <div className={styles['btn']}>
            <Button color="soft-blue" label="OK" action={() => setIsShow(false)} />
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default FileErrorPopup;
