import React, { useRef, useCallback } from 'react';
import { Field } from 'formik';

import styles from './CustomField.module.scss';
import ArrowUpIcon from '@/icons/ArrowUpIcon';
import ArrowDownIcon from '@/icons/ArrowDownIcon';

type CoordinateInputPropsType = {
  name: string;
  value: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  label: string;
  error?: string | undefined;
  touched?: boolean | undefined;
  isDisable?: boolean;
  decimal?: number;
};

const CoordinateInput = ({
  name,
  value,
  setFieldValue,
  label,
  error,
  touched,
  isDisable,
  decimal,
}: CoordinateInputPropsType) => {
  const step = 1;
  const numberInputRef = useRef<HTMLInputElement>(null);

  const increaseValue = useCallback(() => {
    if (numberInputRef.current) numberInputRef.current.focus();
    setFieldValue(name, +Number(value + step).toFixed(decimal || 2));
  }, [value, setFieldValue, name, decimal]);

  const desceaseValue = useCallback(() => {
    if (numberInputRef.current) numberInputRef.current.focus();
    setFieldValue(name, +Number(value - step).toFixed(decimal || 2));
  }, [value, setFieldValue, name, decimal]);

  return (
    <div className={styles['coordinate-input-wrapper']}>
      <span className={`${styles['coordinate-label']} ${isDisable ? 'text-gray4' : ''}`}>
        {label}
      </span>
      <Field
        innerRef={numberInputRef}
        type="number"
        name={name}
        className={`${styles['input-coordinate']} ${styles['number']} ${
          error && touched ? styles['error'] : ''
        } ${isDisable ? 'text-gray4' : ''}`}
        step={1}
        value={value}
        disabled={isDisable}
      />
      <div className={` ${styles['controls']} ${isDisable ? styles['controls-disable'] : ''}`}>
        <button
          className={`${
            isDisable ? styles['number-field-btn-disable'] : styles['number-field-btn']
          } `}
          type="button"
          onClick={increaseValue}
          disabled={isDisable}
        >
          <ArrowUpIcon fill={isDisable ? 'gray' : 'black'} />
        </button>
        <button
          className={`${
            isDisable ? styles['number-field-btn-disable'] : styles['number-field-btn']
          } `}
          type="button"
          onClick={desceaseValue}
          disabled={isDisable}
        >
          <ArrowDownIcon fill={isDisable ? 'gray' : 'black'} />
        </button>
      </div>
    </div>
  );
};

export default React.memo(CoordinateInput);
