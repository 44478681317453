import React, { useRef } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

import styles from './SearchBox.module.scss';
import SearchIcon from '@/icons/SearchIcon';
import useScreen from '@/custom-hooks/useScreen';
import ClearIcon from '@/icons/ClearIcon';

type SearchBoxPropsType = {
  searchState: RecoilState<string>;
  handleSearch?: (key: string) => void;
  handleClear: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const SearchBox = ({ searchState, handleSearch, handleClear }: SearchBoxPropsType) => {
  const [searchValue, setSearchValue] = useRecoilState(searchState);
  const searchRef = useRef<HTMLInputElement>(null);
  const screen = useScreen();

  return (
    <div className={styles['search-form']}>
      <input
        ref={searchRef}
        className={styles['search-input']}
        minLength={2}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder={'SEARCH'}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && searchValue && searchValue.length > 2) {
            handleSearch && handleSearch(searchValue);
            searchRef.current && !screen.isDesktop && searchRef.current.blur();
          }
        }}
      />
      {searchValue.length > 0 && (
        <div className={styles['clear-icon']} onClick={(event) => handleClear(event)}>
          <ClearIcon className="stroke" />
        </div>
      )}
      <div className={`${styles['search-icon']}`}>
        <SearchIcon className="stroke" />
      </div>
    </div>
  );
};

export default SearchBox;
