import React from 'react';

type ColorSquarePropsType = {
  color: string;
  width?: number;
  height?: number;
};

const ColorSquare = ({ color, width, height }: ColorSquarePropsType) => {
  return (
    <div className="relative">
      <div
        className="p-[1px] w-[30px] h-[30px] rounded-[7px] bg-[#fff]"
        style={{ width: `${width || 30}px`, height: `${height || 30}px` }}
      >
        <div className=" w-full h-full rounded-[7px]" style={{ backgroundColor: `${color}` }}></div>
      </div>
    </div>
  );
};

export default React.memo(ColorSquare);
