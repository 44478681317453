import React from 'react';

import styles from './LoadingComponent.module.scss';

const LoadingComponent = () => {
  return (
    <div className="flex justify-center">
      <div className={styles['lds-ring']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingComponent;
