import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { fileErrorPopupState } from '../../recoil/atom/popup';

import styles from './CustomField.module.scss';
import FileErrorPopup from '../Popup/FileErrorPopup';
import { ToolTip } from '../Form';
import { MAX_FILE_SIZE_IMAGE_UPLOAD } from '@/utils/constants';
import useGenerateSasUri from '@/custom-hooks/useGenerateSasUri';

type UploadImagedPropsType = {
  name: string;
  placeholder?: string;
  label: string;
  isRequired?: boolean;
  wrapperClassname?: string;
  hasTooltip?: boolean;
  tooltipHeading?: string;
  tooltipContent?: string;
  setCbFiles?: (files?: File[] | undefined) => void;
  currentLogoUrl?: string;
  resetFile?: boolean;
  setResetFile?: (val: any) => void | boolean;
  tooltipClassname?: string;
  accept?: { [key: string]: string[] };
  error?: boolean;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  dimensions?: { width: number; height: number };
  isDisable?: boolean;
  description?: string;
  sasUriImage?: string;
};

const UploadImage = ({
  label,
  name,
  isRequired,
  wrapperClassname,
  hasTooltip,
  tooltipContent,
  tooltipHeading,
  setCbFiles,
  currentLogoUrl,
  resetFile,
  setResetFile,
  tooltipClassname,
  accept,
  error,
  setError,
  dimensions,
  isDisable,
  description,
  sasUriImage,
}: UploadImagedPropsType) => {
  accept = accept ?? {
    'image/jpeg': ['.jpg', '.png', '.jpeg'],
    'image/png': ['.png'],
  };
  dimensions = dimensions ?? {
    width: 512,
    height: 512,
  };
  const setOpenFileErrorPopup = useSetRecoilState(fileErrorPopupState);
  const [isShow] = useRecoilState(fileErrorPopupState);
  const [files, setFiles] = useState<any>([]);

  const { url: uriImage } = useGenerateSasUri(sasUriImage || '');

  function validator(file: File) {
    const { name, type, size } = file;
    const names = name.split('.');
    const ext = names[names.length - 1];
    const acceptExt = accept ? accept[type] : [];
    const inValidExtension = acceptExt && !acceptExt.includes(`.${ext.toLowerCase()}`);
    const inValidFileSize = size > MAX_FILE_SIZE_IMAGE_UPLOAD;

    if (inValidExtension || inValidFileSize) {
      return {
        code: inValidExtension ? 'inValidExtension' : 'inValidFileSize',
        message: 'Invalid extension or invalid file size',
      };
    }
    return null;
  }
  const { width: w = 512, height: h = 512 } = dimensions;
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    validator,
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_FILE_SIZE_IMAGE_UPLOAD,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        setOpenFileErrorPopup(true);
        if (setCbFiles) {
          setCbFiles(undefined);
          setFiles([]);
        }
      }

      const files = acceptedFiles.map((file) => {
        const url = URL.createObjectURL(file);
        const img = new Image();
        img.src = url;
        img.onload = function () {
          const { width, height } = img;
          if (width > w || height > h) {
            setOpenFileErrorPopup(true);
            if (setCbFiles) {
              setCbFiles(undefined);
              setFiles([]);
            }
          }
        };
        Object.assign(file, {
          preview: url,
        });
        return file;
      });

      setFiles(files);
      if (acceptedFiles.length && setCbFiles) {
        setCbFiles(acceptedFiles);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleClickInsideUploadZone = useCallback(() => {
    if (error && setError) setError(false);
  }, [setError, error]);

  useEffect(() => {
    if (resetFile && setResetFile && isShow) {
      setResetFile(false);
      setFiles([]);
    }
  }, [resetFile, setResetFile, isShow]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => {
        URL.revokeObjectURL(file.preview);
      });
  }, [files]);

  const renderInputIsDisable = useMemo(() => {
    return (
      <div className="relative w-full lg:w-auto cursor-none">
        <div className={`${styles['drag-drop-img']} ${error && styles['error']}`}>
          <input {...getInputProps()} name={name} disabled={isDisable} />
          <p className="text-[16px] leading-[18px] text-gray4 font-[400] text-center">
            Drag and drop or <br /> click here to upload
          </p>
        </div>
        <div className="flex justify-between pt-[6px]">
          <h5
            className={`text-[12px] leading-normal ${error ? 'text-[#ff0000]' : 'text-[#828282]'}`}
          >
            {description ?? '1920x1080 PNG/JPEG/JPG'}
          </h5>
        </div>
        <>
          {(files.length > 0 || currentLogoUrl || uriImage) && (
            <div className="mt-[9px] lg:mt-0 lg:block lg:absolute lg:top-0 lg:left-[calc(100%+18px)] w-[100px] h-[100px]">
              {files.length > 0 ? (
                <>
                  {files.map((file: any) => (
                    <div className="w-full h-full" key={file.name}>
                      <img
                        src={file.preview}
                        alt="Preview"
                        className="w-full h-full object-contain object-top"
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(file.preview);
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="w-full h-full">
                    <img
                      src={currentLogoUrl || uriImage}
                      alt="Logo"
                      className="w-full h-full object-contain object-top"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </>
      </div>
    );
  }, [currentLogoUrl, error, files, getInputProps, isDisable, name, description, uriImage]);

  return (
    <div className="lg:inline-block relative">
      <div className={`flex flex-wrap items-start ${wrapperClassname || 'mb-[20px]'}`}>
        <label
          htmlFor={name}
          className={`block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right lg:mt-[8px] shrink-0 ${
            isDisable ? 'text-gray4' : ''
          }`}
        >
          {label}
          {isRequired && <span className="text-red">*</span>}
        </label>
        {!isDisable ? (
          <div className="relative w-full lg:w-auto">
            <div
              {...getRootProps({
                className: `${styles['drag-drop-img']} ${error ? styles['error'] : ''}`,
                onClick: handleClickInsideUploadZone,
              })}
            >
              <input {...getInputProps()} name={name} />
              <p className="text-[16px] leading-[18px] text-gray4 font-[400] text-center">
                Drag and drop or <br /> click here to upload
              </p>
            </div>
            <div className="flex justify-between pt-[6px]">
              <h5
                className={`text-[12px] leading-normal ${
                  error ? 'text-[#ff0000]' : 'text-[#828282]'
                }`}
              >
                {description ?? '1920x1080 PNG/JPEG/JPG'}
              </h5>
            </div>
            <>
              {(files.length > 0 || currentLogoUrl || uriImage) && (
                <div className="mt-[9px] lg:mt-0 lg:block lg:absolute lg:top-0 lg:left-[calc(100%+18px)] w-[100px] h-[100px]">
                  {files.length > 0 ? (
                    <>
                      {files.map((file: any) => (
                        <div className="w-full h-full" key={file.name}>
                          <img
                            src={file.preview}
                            alt="Preview"
                            className="w-full h-full object-contain object-top"
                            // Revoke data uri after image is loaded
                            onLoad={() => {
                              URL.revokeObjectURL(file.preview);
                            }}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="w-full h-full">
                        <img
                          src={currentLogoUrl || uriImage}
                          alt="Logo"
                          className="w-full h-full object-contain object-top"
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          </div>
        ) : (
          renderInputIsDisable
        )}
      </div>
      {hasTooltip && (
        <ToolTip
          wrapperClassName={`absolute top-[0] right-[14px] ${
            files.length > 0 || currentLogoUrl || uriImage
              ? 'lg:top-[110] lg:right-[-147px]'
              : 'lg:top-[10px] lg:right-[-47px]'
          } ${tooltipClassname}`}
          tooltipContent={tooltipContent}
          tooltipHeading={tooltipHeading}
        />
      )}
      <FileErrorPopup />
    </div>
  );
};

export default UploadImage;
