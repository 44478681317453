import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingOverlay from "@/components/LoadingOverlay";
import AppRoutes from "./routes";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Pleasant Company Hub Client Portal</title>
      </Helmet>
      <RecoilRoot>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}
        >
          <Suspense fallback={<LoadingOverlay />}>
            <AppRoutes />
          </Suspense>
        </SWRConfig>
      </RecoilRoot>
    </HelmetProvider>
  );
}

export default App;
