import React from 'react';
import { Field } from 'formik';

import styles from './CustomField.module.scss';
import TickSquareIcon from '@/icons/TickSquareIcon';
import { ToolTip } from '../Form';

type TickFieldProps = {
  name: string;
  label: string;
  isRequired?: boolean;
  value: boolean | any;
  wrapperClassname?: string;
  hasTooltip?: boolean;
  tooltipHeading?: string;
  tooltipContent?: string;
  isDisable?: boolean;
  tooltipClassname?: string;
  isNotFormikField?: boolean;
  onChange?: (e: any) => void;
  isChecked?: boolean;
  isMobileLabelRight?: boolean;
};

const TickField = ({
  label,
  name,
  isRequired,
  value,
  wrapperClassname,
  hasTooltip,
  tooltipContent,
  tooltipHeading,
  isDisable,
  tooltipClassname,
  isNotFormikField,
  onChange,
  isChecked,
  isMobileLabelRight,
}: TickFieldProps) => {
  return (
    <div>
      <div className="inline-block relative">
        <div className={`flex flex-wrap items-center ${wrapperClassname || 'mb-[20px]'}`}>
          {label && (
            <label
              htmlFor={name}
              className={`block text-[16px] leading-[24px] mr-[18px] w-[173px]  ${
                isMobileLabelRight ? 'text-right' : 'text-left'
              } lg:text-right shrink-0 ${isDisable ? 'text-gray4' : ''}`}
            >
              {label}
              {isRequired && <span className="text-red">*</span>}
            </label>
          )}
          <label className="cursor-pointer block text-[16px] leading-[24px]">
            {isNotFormikField ? (
              <input
                type="checkbox"
                className={`${styles['input-field']} ${styles['tick']} ${
                  isDisable ? styles['field-disable'] : ''
                }`}
                checked={isChecked}
                name={name}
                disabled={isDisable}
                onChange={onChange}
                value={value}
              />
            ) : (
              <Field
                name={name}
                className={`${styles['input-field']} ${styles['tick']} ${
                  isDisable ? styles['field-disable'] : ''
                }`}
                type="checkbox"
                disabled={isDisable}
              />
            )}

            <span className={`${styles['tick-icon']} ${isDisable ? styles['field-disable'] : ''}`}>
              <TickSquareIcon fill={value ? '' : 'transparent'} />
            </span>
          </label>
        </div>
        {hasTooltip && (
          <ToolTip
            wrapperClassName={`absolute top-[5px] right-[-47px] ${tooltipClassname}`}
            tooltipContent={tooltipContent}
            tooltipHeading={tooltipHeading}
          />
        )}
      </div>
    </div>
  );
};

export default TickField;
