import React, { useCallback, useRef } from 'react';
import { Field } from 'formik';

import styles from './CustomField.module.scss';

import ArrowDownIcon from '@/icons/ArrowDownIcon';
import ArrowUpIcon from '@/icons/ArrowUpIcon';

type NumberFieldProps = {
  name: string;
  placeholder?: string;
  label: string;
  isRequired?: boolean;
  value: number | string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  wrapperClassname?: string;
};

const NumberField = ({
  label,
  name,
  placeholder,
  isRequired,
  setFieldValue,
  value,
  wrapperClassname,
}: NumberFieldProps) => {
  const numberInputRef = useRef<HTMLInputElement>(null);

  const increaseValue = useCallback(() => {
    if (value === '') {
      setFieldValue(name, 1);
    }

    if (numberInputRef.current) numberInputRef.current.focus();

    if (typeof value === 'number') {
      setFieldValue(name, value + 1);
    }
  }, [value, setFieldValue, name]);

  const desceaseValue = useCallback(() => {
    if (value === '') {
      return;
    }

    if (numberInputRef.current) numberInputRef.current.focus();

    if (typeof value === 'number' && value > 0) {
      setFieldValue(name, value - 1);
    }
  }, [value, setFieldValue, name]);

  return (
    <div className={`flex flex-wrap items-center ${wrapperClassname || 'mb-[20px]'}`}>
      <label
        htmlFor={name}
        className="block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right shrink-0"
      >
        {label}
        {isRequired && <span className="text-red">*</span>}
      </label>
      <div className={styles['input-number-wrapper']}>
        <Field
          innerRef={numberInputRef}
          type="number"
          name={name}
          placeholder={placeholder || ''}
          className={`${styles['input-field']} ${styles['number']}`}
        />
        <div className={styles['controls']}>
          <button className={styles['number-field-btn']} onClick={increaseValue}>
            <ArrowUpIcon />
          </button>
          <button className={styles['number-field-btn']} onClick={desceaseValue}>
            <ArrowDownIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberField;
