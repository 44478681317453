import React, { FunctionComponent, SVGProps } from "react";

export const EditIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28937 12.3563L20.3137 1.35031C22.119 -0.450102 25.0449 -0.450102 26.8502 1.35031L28.6459 3.14301C30.4514 4.94553 30.4514 7.86899 28.6459 9.66957L17.5689 20.7282C16.7609 21.5349 15.6651 21.9883 14.5223 21.9883H8.9963C8.3745 21.9883 7.87482 21.4769 7.89028 20.8563L8.02895 15.2891C8.05676 14.1866 8.50783 13.1365 9.28937 12.3563ZM27.0813 4.70505L25.2865 2.91318C24.3451 1.97435 22.8189 1.97435 21.8779 2.91276L20.971 3.8182L26.1744 9.01295L27.0817 8.10709C28.0226 7.16885 28.0226 5.64473 27.0813 4.70505ZM24.6097 10.575L19.4063 5.38024L10.8541 13.9183C10.4739 14.2978 10.2546 14.8085 10.241 15.3444L10.1296 19.7779L14.5223 19.7792C15.0086 19.7792 15.4775 19.6104 15.8506 19.3052L16.0043 19.1661L24.6097 10.575ZM14.0022 0.0569408C14.6132 0.0569408 15.1086 0.551457 15.1086 1.16147C15.1086 1.72066 14.6924 2.18279 14.1523 2.25592L14.0022 2.26601H8.48659C4.7669 2.26601 2.35756 4.72943 2.21905 8.55496L2.21274 8.90646V21.1506C2.21274 25.0629 4.50492 27.6363 8.15139 27.7843L8.48659 27.791H21.5034C25.2323 27.791 27.6344 25.3329 27.7724 21.5025L27.7787 21.1506V15.2185C27.7787 14.6085 28.2741 14.114 28.8851 14.114C29.4452 14.114 29.9081 14.5295 29.9814 15.0686L29.9915 15.2185V21.1506C29.9915 26.2492 26.7564 29.8342 21.8485 29.9945L21.5034 30.0001H8.48659C3.50296 30.0001 0.154842 26.5308 0.00522889 21.5038L0 21.1506V8.90646C0 3.81278 3.24338 0.22309 8.14215 0.062553L8.48659 0.0569408H14.0022Z"
        fill={props.fill || "black"}
      />
    </svg>
  );
};

export default EditIcon;
