import React, { FunctionComponent, SVGProps } from "react";

export const TickSquareIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="28"
        height="28"
        rx="7"
        stroke={props.stroke || "black"}
        strokeWidth="2"
      />
      <path
        d="M19.5 16.366L12.75 20.2631C12.0833 20.648 11.25 20.1669 11.25 19.3971V11.6029C11.25 10.8331 12.0833 10.352 12.75 10.7369L19.5 14.634C20.1667 15.0189 20.1667 15.9811 19.5 16.366Z"
        stroke={props.stroke || "black"}
        strokeWidth="2"
      />
    </svg>
  );
};

export default TickSquareIcon;
