import React, { FunctionComponent, SVGProps } from "react";

export const SearchIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97321 9.65L12.1439 11.75M11.4439 6.15C11.4439 8.8562 9.25012 11.05 6.54392 11.05C3.83773 11.05 1.64392 8.8562 1.64392 6.15C1.64392 3.4438 3.83773 1.25 6.54392 1.25C9.25012 1.25 11.4439 3.4438 11.4439 6.15Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SearchIcon;
