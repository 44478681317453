import React from "react";

import Button from "@/components/Button";
import { InstancesTable, GalleriesTable } from "@/components/Table";
import { ExampleForm } from "@/components/Form";

import Layout from "@/components/Layout";
import Container from "@/components/Container";

const SamplePage = () => {
  return (
    <Layout>
      <div style={{ height: "20px" }}></div>
      <Container>
        Button examples
        <div className="p-[10px]">
          <Button color="black" label="Instances" action={() => undefined} />
        </div>
        <div className="p-[10px]">
          <Button
            size="large"
            color="black"
            label="Instances"
            action={() => undefined}
          />
        </div>
        <div className="p-[10px]">
          <Button
            color="soft-blue"
            label="Instances"
            action={() => undefined}
          />
        </div>
        <div className="p-[10px]">
          <Button
            color="bright-blue"
            label="Instances"
            action={() => undefined}
          />
        </div>
        <div className="p-[10px]">
          <Button
            disable
            color="bright-blue"
            label="Instances"
            action={() => undefined}
          />
        </div>
        <div className="p-[10px]">
          <Button type="link" href="" color="bright-blue" label="Link" />
        </div>
      </Container>
      <div style={{ height: "20px" }}></div>
      <Container>
        <div className="pb-[20px]">Examples of table has horizontal scroll</div>
      </Container>
      <InstancesTable />
      <div style={{ height: "20px" }}></div>
      <div className="hidden md:block">
        <GalleriesTable />
      </div>
      <div style={{ height: "20px" }}></div>
      <ExampleForm />
      <div style={{ height: "20px" }}></div>
    </Layout>
  );
};

export default SamplePage;
