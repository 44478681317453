import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { GLOBAL_CONST } from "@/utils/constants";
import { BASE_URL } from "@/config/app.config";

export default function useLogoutAction() {
  const { logout } = useAuth0();
  return useCallback(() => {
    localStorage.setItem(
      `${GLOBAL_CONST.LS_CALLBACK_PAGE}`,
      window.location.pathname === "/forbidden"
        ? "/dashboard"
        : window.location.pathname
    );

    logout({
      logoutParams: {
        returnTo: `${BASE_URL}/logout`,
        federated: true,
        openUrl: false,
      },
    });
  }, [logout]);
}
