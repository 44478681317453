export const BASE_URL = (window.location.origin || "") as string;
export const AUTH0_DOMAIN = ((BASE_URL === "https://hubportal.pleasant.company"
  ? "https://dev-jl27vykmvcgyjrum.us.auth0.com"
  : "https://dev-jl27vykmvcgyjrum.us.auth0.com") || "") as string;
export const AUTH0_AUDIENCE_URL = "https://hubapi.pleasant.company" as string;
export const AUTH0_CLIENT_ID = ((BASE_URL ===
"https://hubportal.pleasant.company"
  ? "6tLQ7D1WvkjeMKmT8WnpjhPsFEHEym2v"
  : "2bmbT3bKp83J2JCmMQ97pLLDCYztGlef") || "") as string;

var apiUrl: string = "";
if (BASE_URL === "https://hubportal.pleasant.company") {
  apiUrl = "https://hubapi.pleasant.company/v1";
} else if (BASE_URL === "http://localhost:3000") {
  apiUrl = "http://localhost:7071/v1";
} else {
  apiUrl = "https://stagingapi.curiious.com/v1";
}
export const API_URL = apiUrl;
