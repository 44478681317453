import { atom } from "recoil";

export const fileErrorPopupState = atom({
  key: "fileErrorPopupState",
  default: false,
});

export const deletePopupState = atom({
  key: "deletePopupState",
  default: false,
});

export const toastPopupState = atom({
  key: "toastPopupState",
  default: false,
});

export const blockNavigationPopupState = atom({
  key: "blockNavigationPopupState",
  default: false,
});

export const errorPopupState = atom({
  key: "errorPopupState",
  default: false,
});

export const refreshPopupState = atom({
  key: "refreshPopupState",
  default: false,
});

export const announcementPopupState = atom({
  key: "announcementPopupState",
  default: false,
});

export const passwordResetEmailPopupState = atom({
  key: "passwordResetEmailPopupState",
  default: false,
});

export const unblockPopupState = atom({
  key: "unblockPopupState",
  default: false,
});
