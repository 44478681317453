import React, { useState, useRef } from 'react';

import useClickOutside from '@/custom-hooks/useClickOutside';
import useScreen from '@/custom-hooks/useScreen';

import InfoIcon from '@/icons/InfoIcon';

import styles from './Form.module.scss';
import { ToolTipPopup } from '../Popup';

type ToolTipPropsType = {
  wrapperClassName?: string;
  tooltipHeading?: string;
  tooltipContent?: string;
};

const ToolTip = ({ wrapperClassName, tooltipHeading, tooltipContent }: ToolTipPropsType) => {
  const screen = useScreen();
  const [isOpenToolTip, setIsOpenToolTip] = useState(false);
  const infoRef = useRef(null);
  const infoIconRef = useRef<HTMLDivElement>(null);

  useClickOutside(infoRef, (e) => {
    if (infoIconRef.current && !infoIconRef.current.contains(e.target)) setIsOpenToolTip(false);
  });

  return (
    <div className={`${wrapperClassName} block`}>
      <div className="relative">
        <div
          ref={infoIconRef}
          className={styles['tooltip-icon']}
          onClick={() => {
            setIsOpenToolTip(!isOpenToolTip);
          }}
        >
          <InfoIcon className="stroke" />
        </div>
        <div
          ref={infoRef}
          className={`${styles['tooltip-info']} ${screen.isDesktop ? '' : styles['mobile']} ${
            isOpenToolTip ? styles['open'] : ''
          }`}
        >
          <ToolTipPopup heading={tooltipHeading} content={tooltipContent} />
        </div>
      </div>
    </div>
  );
};

export default ToolTip;
