import React, { useEffect } from 'react';

type SideEffectFieldPropTypes = {
  setStateAction?: React.Dispatch<React.SetStateAction<boolean>>;
  isDirty: boolean;
};

const SideEffectField = ({ isDirty, setStateAction }: SideEffectFieldPropTypes) => {
  useEffect(() => {
    if (setStateAction) setStateAction(Boolean(isDirty));
  }, [isDirty, setStateAction]);

  return null;
};

export default SideEffectField;
