import React, { useRef } from 'react';
import { useFormikContext } from 'formik';

import styles from './CustomField.module.scss';

type TextInputFieldProps = {
  name: string;
  placeholder?: string;
  label: string;
  isRequired?: boolean;
  wrapperClassname?: string;
  touched?: boolean | undefined;
  error?: string;
};

const UploadCSV = ({ label, name, isRequired, wrapperClassname, error }: TextInputFieldProps) => {
  const ref = useRef<any>({});
  const { setFieldValue } = useFormikContext();

  return (
    <div className={`flex flex-wrap items-center ${wrapperClassname || 'mb-[20px]'}`}>
      <label
        htmlFor={name}
        className="block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right shrink-0"
      >
        {label}
        {isRequired && <span className="text-red">*</span>}
      </label>
      <div className="flex flex-col w-full lg:w-[600px]">
        <input
          type="file"
          name={name}
          accept=".csv"
          onChange={(e: any) => {
            if (e?.target?.files?.length) {
              const file: File = e?.target?.files[0];
              setFieldValue(name, file);
            }
          }}
          ref={ref}
          className={`${styles['input-field']} ${styles['file']} ${error ? styles['error'] : ''} ${
            styles['gray-placeholder']
          }`}
        />
        {error && <span className={`${styles['error-message']}`}>{error}</span>}
      </div>
    </div>
  );
};

export default UploadCSV;
