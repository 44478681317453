import React from 'react';

import styles from './Table.module.scss';
import ArrowDownIcon from '@/icons/ArrowDownIcon';
import { SORTING_TYPE } from '@/utils/constants';

type ClickableHeadingPropsType = {
  title: string;
  action: () => void;
  spacing: number;
  isUppercase?: boolean;
  orderState: SORTING_TYPE;
};

const ClickableHeading = ({
  title,
  action,
  spacing,
  isUppercase = true,
  orderState,
}: ClickableHeadingPropsType) => {
  return (
    <div
      className={`${styles['clickable-heading']} ${isUppercase ? styles['isUppercase'] : ''}`}
      onClick={() => {
        action();
      }}
    >
      <h3 style={{ paddingRight: `${spacing}px` }}>{title}</h3>
      <div
        className="transition-all"
        style={{
          transform: `rotate(${orderState === SORTING_TYPE.ASCENDING ? '0deg' : '-180deg'})`,
        }}
      >
        <ArrowDownIcon className="no-hover" />
      </div>
    </div>
  );
};

export default React.memo(ClickableHeading);
