import React, { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

import LoadingOverlay from '@/components/LoadingOverlay';
import useCheckUserRole from '@/custom-hooks/useCheckUserRole';
import { USER_ROLE } from '@/utils/constants';

type AuthenticationGuardPropTypes = {
  component: ComponentType;
  minimumAccessLevel: USER_ROLE;
};

const AuthenticationGuard = ({ component, minimumAccessLevel }: AuthenticationGuardPropTypes) => {
  const role = useCheckUserRole();
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingOverlay />,
  });

  if (role.isUserDataLoading) return <LoadingOverlay />;

  // Divert to forbidden if the minimum access level isnt achieved.
  if (role.userData && (role.userData.role as number) < minimumAccessLevel.valueOf()) {
    return <Navigate to="/forbidden" />;
  }

  return <Component />;
};

export default AuthenticationGuard;
