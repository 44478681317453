import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './Header.module.scss';
import { URLS } from '@/utils/constants';
import ArrowRightIcon from '@/icons/ArrowRightIcon';

type NavItemPropsType = {
  label: string;
  items: {
    to: string;
    label: string;
  }[];
  openOnDefault: boolean;
};

const ExpandableNavItem = ({ label, items, openOnDefault }: NavItemPropsType) => {
  const location = useLocation();
  const [isNavExpanding, setIsNavExpanding] = useState(openOnDefault || false);
  const subItemWrapperRef = useRef<HTMLUListElement>(null);
  const [subListHeight, setSubListHeight] = useState(
    openOnDefault ? subItemWrapperRef.current?.clientHeight : 0,
  );

  useEffect(() => {
    const handleGetSubWrapperHeight = () => {
      if (subItemWrapperRef.current) {
        setSubListHeight(subItemWrapperRef.current.clientHeight);
      }
    };

    handleGetSubWrapperHeight();
    // eslint-disable-next-line
    if (typeof window !== undefined) {
      window.addEventListener('resize', handleGetSubWrapperHeight);
    }

    return () => {
      // eslint-disable-next-line
      if (typeof window !== undefined) {
        window.removeEventListener('resize', handleGetSubWrapperHeight);
      }
    };
  }, [items.length, openOnDefault]);

  const handleToggleNav = useCallback(() => {
    setIsNavExpanding(!isNavExpanding);
  }, [isNavExpanding]);

  return (
    <li className="mt-[16px] xl:mt-[26px] first:mt-[0px]">
      <div
        onClick={handleToggleNav}
        className="flex items-center justify-center xl:justify-start transition-all cursor-pointer h-[40px] xl:h-[auto]"
      >
        <p className="leading-[40px] text-[20px] xl:leading-[24px] uppercase">{label}</p>
        <span className={`ml-[12px] transition-all ${isNavExpanding ? 'rotate-90' : 'rotate-0'}`}>
          <ArrowRightIcon className="no-hover" />
        </span>
      </div>
      <div
        className={styles['sub-item-wrapper']}
        style={{ maxHeight: `${isNavExpanding ? subListHeight : 0}px` }}
      >
        <ul ref={subItemWrapperRef} className="pl-0 xl:pl-[20px] xl:pt-[5px] pb-[5px] xl:pb-0">
          {items.map((i, idx) => (
            <li key={idx} className="pt-[6px] xl:pt-[4px] first:pt-0">
              <NavLink
                to={`/${i.to}`}
                className={({ isActive }) =>
                  `block leading-[30px] text-[20px] xl:leading-[24px] uppercase transition-all cursor-pointer hover:text-soft-blue h-[30px] xl:h-[auto] ${
                    isActive || (location.pathname === '/user-result' && i.to === URLS.USER)
                      ? 'text-soft-blue underline'
                      : ''
                  }`
                }
              >
                {i.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default React.memo(ExpandableNavItem);
