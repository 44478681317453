import React from 'react';

import styles from './Loading.module.scss';
import Overlay from '../Overlay';

const LoadingOverlay = () => {
  return (
    <Overlay isShow={true}>
      <div className={styles['lds-ring']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Overlay>
  );
};

export default LoadingOverlay;
