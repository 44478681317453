import React, { useEffect, useMemo, useState, useRef } from 'react';

import Button from '../Button';

import { AddressablesType } from '@/types/addressable';

type PreviewMusicButtonPropsType = {
  musicAddressable: number | null;
  musics: AddressablesType[] | undefined;
};

const PreviewMusicButton = ({ musicAddressable, musics }: PreviewMusicButtonPropsType) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const musicKey = useMemo(() => {
    if (musicAddressable && musics && musics.length > 0) {
      const selectedMusicKey = musics.find(
        (item) => item.id.toString() === musicAddressable.toString(),
      );

      return selectedMusicKey ? selectedMusicKey.key : '';
    }

    return '';
  }, [musics, musicAddressable]);
  const [audioSrc, setAudioSrc] = useState('');
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const handlePlayAudio = () => {
    if (audioRef.current) {
      const audio = audioRef.current;
      if (audio.duration > 0 && !audio.paused) {
        setIsAudioPlaying(false);
        audio.pause();
      } else {
        audio.play();
        setIsAudioPlaying(true);
      }
    }
  };

  useEffect(() => {
    const audioDomain = 'https://curiiousportalprod.z8.web.core.windows.net/Media';
    if (musicKey) {
      setAudioSrc(`${audioDomain}/${musicKey}.mp3`);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener('ended', () => {
          setIsAudioPlaying(false);
        });
        audioRef.current.load();
        audioRef.current.addEventListener('ended', () => {
          setIsAudioPlaying(false);
        });
        setIsAudioPlaying(false);
      }
    }
  }, [musicKey, musicAddressable]);

  return (
    <>
      <audio ref={audioRef} preload="true" src={audioSrc}></audio>
      <Button
        color="soft-blue"
        label={isAudioPlaying ? 'PAUSE MUSIC' : 'PREVIEW MUSIC'}
        action={handlePlayAudio}
        disable={!musicKey}
      />
    </>
  );
};

export default PreviewMusicButton;
