import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Container from '../Container';
import {
  TextInputField,
  TextAreaField,
  MultiSelectField,
  DropdownField,
  NumberField,
  TickField,
  ColorField,
  UploadCSV,
  UploadImage,
  CoordinateField,
} from '../CustomField';
import ToolTip from './ToolTip';
import Button from '../Button';
import FormLink from './FormLink';

export type InitValuesType = {
  numberValue: number | string;
  tickValue: boolean;
  colorPrimary: string;
  colorSeconday: string;
  requiredText: string;
  csvFile: File | undefined;
  text1: string;
  longText: string;
  textArea: string;
  dropdown: string;
  multiSelect: string[];
  xPos: number;
  yPos: number;
  zPos: number;
};

const validationSchema = Yup.object().shape({
  requiredText: Yup.string().required(),
});

const ExampleForm = () => {
  const initValues: InitValuesType = {
    text1: '',
    numberValue: '',
    tickValue: false,
    colorPrimary: '#ad0b9d',
    colorSeconday: '#0b4cad',
    requiredText: '',
    csvFile: undefined,
    longText: '',
    textArea: '',
    dropdown: '',
    multiSelect: [],
    xPos: 0,
    yPos: 0,
    zPos: 0,
  };
  return (
    <Container>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ values, setFieldValue, errors, touched, handleSubmit }) => (
          <Form>
            <MultiSelectField
              label="Scrollable list with multi-select"
              name="multiSelect"
              options={multiSelectOptions}
              value={values.multiSelect}
            />
            <TextInputField label="Text field 1" name="text1" placeholder="Text field 1" />
            <div className="lg:inline-block relative">
              <TextInputField
                label="Text field 2"
                name="requiredText"
                placeholder="Text field 2"
                isRequired
                error={errors.requiredText}
                touched={touched.requiredText}
              />
              <ToolTip wrapperClassName="absolute top-[0] right-[14px] lg:top-[10px] lg:right-[-47px]" />
            </div>
            <TextInputField
              label="SSO Connection name (optional)"
              name="longText"
              placeholder="Long label field"
            />
            <TextAreaField label="Text area" name="textArea" placeholder="Text area label" />
            <DropdownField
              value={values.dropdown}
              label="Dropdown field"
              name="dropdown"
              options={multiSelectOptions}
            />
            <NumberField
              value={values.numberValue}
              label="Number field 1"
              name="numberValue"
              placeholder="Number field 1"
              setFieldValue={setFieldValue}
            />
            <TickField value={values.tickValue} label="Tick field" name="tickValue" />
            <div className="flex flex-wrap">
              <ColorField
                label="Primary Colour"
                name="colorPrimary"
                value={values.colorPrimary}
                setFieldValue={setFieldValue}
              />
              <ColorField
                label="Secondary Colour"
                name="colorSeconday"
                value={values.colorSeconday}
                setFieldValue={setFieldValue}
              />
            </div>
            <FormLink linkLabel="Links on this website will be black and underlined" />
            <UploadCSV label="Upload CSV" name="csvFile" />
            <UploadImage
              label="Key Image"
              name=""
              description="512 x 512 pixels, .jpeg, .png or .jpg file type only"
            />
            <CoordinateField
              label="Position"
              setFieldValue={setFieldValue}
              wrapperClassname="mb-[25px]"
              nameX={'xPos'}
              nameY="yPos"
              nameZ="zPos"
              valueX={values.xPos}
              valueY={values.yPos}
              valueZ={values.zPos}
            />
            <div className="md:pl-[191px]">
              <Button color="black" label="Submit" action={() => handleSubmit()} />
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ExampleForm;

const multiSelectOptions = [
  {
    id: 1,
    name: 'Selection 1',
  },
  {
    id: 6,
    name: 'Selection 6',
  },
  {
    id: 3,
    name: 'Selection 3',
  },
  {
    id: 4,
    name: 'Selection 4',
  },
  {
    id: 5,
    name: 'Selection 5',
  },

  {
    id: 7,
    name: 'Selection 7',
  },
  {
    id: 2,
    name: 'Selection 2',
  },
];
