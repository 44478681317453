import React, { useCallback, useEffect, useMemo, useState } from 'react';
import parse from 'html-react-parser';

import styles from './Table.module.scss';
import './index.scss';
import ClickableHeading from './ClickableHeading';
import { SORTING_TYPE, TABLE_PAGINATION } from '@/utils/constants';
import { cloneDeep } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingComponent from '../LoadingComponent';

export interface IColumns {
  title: string; //name title th
  key: string; //key
  dataIndex?: string; // field data and use sort need field
  render?: (row: any, index: number) => JSX.Element | undefined; //custom td
  renderHeader?: (col: any, index: number) => JSX.Element | undefined; //custom th and can't use sort(need to add ClickableHeading).
  align?: 'left' | 'right' | 'center';
  width?: string; // custom width td-th
  sort?: SORTING_TYPE; //sort (ASCENDING or DESCENDING)
  type?: 'image' | 'icon'; // types to group image and buttons to one row on mobile
  renderFieldMobile?: (col: any, index: number) => JSX.Element | undefined; // function to render on mobile
  mobileTitle?: string; // optional for title need to break in the middle of the word - see description row in mobile instance for example
  mobileLongText?: boolean; // If true, text should truncated after 4 lines, otherwise 2 lines
}
interface IProps {
  isAutoWidth?: boolean;
  dataSource: any[]; //list Data (Data of API or Hard Data)
  columns: IColumns[];
  setIsLoading: (value: boolean) => void; //set loading
  renderColGroup?: () => JSX.Element | undefined; // custom <colgroup></colgroup>, So when you use it. Need custom renderHeader and no width in columns
}
const CommonTable = ({
  dataSource,
  columns,
  renderColGroup,
  setIsLoading,
  isAutoWidth,
}: IProps) => {
  const PAGINATION = TABLE_PAGINATION;
  const [currentListData, setCurrentListData] = useState<any[]>([]);
  const [listColumns, setListColumns] = useState<IColumns[]>([]);
  const [instances, setInstances] = useState<any[]>([]);
  const [totalShowing, setTotalShowing] = useState(PAGINATION);

  const handleIncreasePagination = useCallback(() => {
    if (instances) {
      if (totalShowing >= instances.length) return;

      setTotalShowing((prev) => prev + PAGINATION);
    }
  }, [instances, totalShowing, PAGINATION]);

  const handleInitialInstancesData = useCallback(() => {
    setListColumns(cloneDeep(columns));
    if (dataSource) {
      setInstances(cloneDeep(dataSource));
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [columns, dataSource, setIsLoading]);

  useEffect(() => {
    handleInitialInstancesData();
  }, [columns, dataSource, handleInitialInstancesData]);

  useEffect(() => {
    if (instances && instances.length < totalShowing) {
      setCurrentListData(instances);
      return;
    }

    if (instances) {
      const showingInstances = instances.filter((instance, idx) => idx + 1 <= totalShowing);
      setCurrentListData(showingInstances);
    }
  }, [instances, totalShowing]);

  useEffect(() => {
    setListColumns(cloneDeep(columns));
  }, [columns]);

  const toggleSort = useCallback(
    (col: IColumns) => {
      if (currentListData) {
        const newListColumn = cloneDeep(listColumns);
        const indexColumn = newListColumn.findIndex((a: IColumns) => a.key === col.key);
        const sort = newListColumn[indexColumn]?.sort;

        const dataIndexStr = `${col.dataIndex}Str`;

        const newDataSource = dataSource.map((d) => {
          return {
            ...d,
            [dataIndexStr]: d[col.dataIndex as string]?.toString()?.trim() || 'z',
          };
        });

        if (sort === SORTING_TYPE.ASCENDING) {
          const ordersData = newDataSource.sort((a: any, b: any) => {
            if (typeof a[col.dataIndex as string] === 'number') {
              return a[col.dataIndex as string] - b[col.dataIndex as string];
            } else {
              return a[dataIndexStr].localeCompare(b[dataIndexStr]);
            }
          });
          newListColumn.forEach((element) => {
            element.sort = SORTING_TYPE.ASCENDING;
          });
          newListColumn[indexColumn].sort = SORTING_TYPE.DESCENDING;
          setListColumns([...newListColumn]);
          setInstances(ordersData);
        } else {
          const ordersData = newDataSource.sort((a: any, b: any) => {
            if (typeof a[col.dataIndex as string] === 'number') {
              return b[col.dataIndex as string] - a[col.dataIndex as string];
            } else {
              return b[dataIndexStr].localeCompare(a[dataIndexStr]);
            }
          });
          newListColumn.forEach((element) => {
            element.sort = SORTING_TYPE.ASCENDING;
          });
          newListColumn[indexColumn].sort = SORTING_TYPE.ASCENDING;
          setListColumns([...newListColumn]);
          setInstances(ordersData);
        }
      }
    },
    [currentListData, listColumns, dataSource],
  );
  const renderSort = useCallback(
    (col: IColumns) => {
      if (col.sort) {
        const indexColumn = listColumns.findIndex((a: IColumns) => a.key === col.key);
        const sort = listColumns[indexColumn]?.sort;
        return (
          <ClickableHeading
            title={col.title}
            action={() => toggleSort(col)}
            spacing={11}
            orderState={sort || SORTING_TYPE.ASCENDING}
          />
        );
      }
      return col.title;
    },
    [listColumns, toggleSort],
  );

  const renderHeader = useMemo(() => {
    return columns.map((col: IColumns) => {
      if (col.renderHeader) {
        return col.renderHeader;
      }
      return (
        <th
          style={{
            textAlign: col.align ? col.align : 'left',
            width: col.width ? col.width : undefined,
          }}
          key={col.key}
        >
          {renderSort(col)}
        </th>
      );
    });
  }, [columns, renderSort]);

  const renderTableData = useCallback(
    (data: any) => {
      return columns.map((col: IColumns, index: number) => {
        if (col.render) {
          return col.render(data, index);
        } else {
          if (renderColGroup) {
            return (
              <td key={col.key}>{col && col?.dataIndex ? (data as any)[col.dataIndex] : ''}</td>
            );
          }
          return (
            <td style={{ textAlign: col.align ? col.align : 'left' }} key={col.key}>
              {col && col?.dataIndex ? (data as any)[col.dataIndex] : ''}
            </td>
          );
        }
      });
    },
    [columns, renderColGroup],
  );

  const renderTableRow = useMemo(() => {
    return currentListData.map((data: any, index: number) => {
      return (
        <tr className="row-data-table" key={`row-${index}`}>
          {renderTableData(data)}
        </tr>
      );
    });
  }, [currentListData, renderTableData]);

  const renderColGroupTable = useMemo(() => {
    if (renderColGroup) {
      return renderColGroup();
    }
    return null;
  }, [renderColGroup]);

  const renderUniqueMobileRow = useCallback(
    (data: any) => {
      const imageFields = columns.filter((field) => field.type === 'image');
      const iconFields = columns.filter((field) => field.type === 'icon');

      return (
        <div className={styles['col-sm']}>
          {imageFields
            .filter((field) => field.type === 'image')
            .map((item, index) => (
              <React.Fragment key={`mobile-image-${index}`}>
                {item.renderFieldMobile && item.renderFieldMobile(data, index)}
              </React.Fragment>
            ))}

          <div className={styles['buttons']}>
            {iconFields
              .filter((field) => field.type === 'icon')
              .map((item, index) => (
                <React.Fragment key={`mobile-icon-${index}`}>
                  {item.renderFieldMobile && item.renderFieldMobile(data, index)}
                </React.Fragment>
              ))}
          </div>
        </div>
      );
    },
    [columns],
  );

  const renderMobileItem = useCallback(
    (data: any) => {
      // eslint-disable-next-line
      return columns.map((field, index) => {
        if (!field.type) {
          if (field.renderFieldMobile) return field.renderFieldMobile(data, index);

          return (
            <div key={`mobile-field-${index}`} className={styles['col-sm']}>
              <div className={styles['title']}>
                {field.mobileTitle ? parse(field.mobileTitle) : field.title}
              </div>
              <div className={styles['content']}>
                <p className={field.mobileLongText ? styles['long'] : styles['short']}>
                  {data[field.dataIndex as string]}
                </p>
              </div>
            </div>
          );
        }
      });
    },
    [columns],
  );

  const renderMobileTable = useCallback(() => {
    return currentListData.map((data: any, index: any) => {
      return (
        <div key={`mo-${index}`} className={styles['table-col-sm']}>
          {renderMobileItem(data)}
          {renderUniqueMobileRow(data)}
        </div>
      );
    });
  }, [currentListData, renderMobileItem, renderUniqueMobileRow]);

  return (
    <InfiniteScroll
      dataLength={currentListData.length}
      next={handleIncreasePagination}
      hasMore={Boolean(instances && totalShowing < instances.length)}
      loader={<LoadingComponent />}
    >
      <table className={`table-container ${isAutoWidth ? 'width-auto' : ''}`}>
        {renderColGroupTable}
        <tbody className="row-body-table">
          <tr className="row-header-table">{renderHeader as any}</tr>
          {renderTableRow}
        </tbody>
      </table>

      <div className="block md:hidden">{renderMobileTable()}</div>
    </InfiniteScroll>
  );
};
export default CommonTable;
