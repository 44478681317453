import { URLS } from '@/utils/constants';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type NavItemPropsType = {
  to: string;
  label: string;
  // customAction?: () => void;
};

const NavItem = ({ to, label }: NavItemPropsType) => {
  const location = useLocation();

  return (
    <li
      // onClick={customAction}
      className="mt-[16px] xl:mt-[26px] first:mt-[0px]"
    >
      <NavLink
        to={`/${to}`}
        className={({ isActive }) =>
          `block leading-[40px] text-[20px] xl:leading-[24px] uppercase transition-all cursor-pointer hover:text-soft-blue h-[40px] xl:h-[auto] ${
            isActive || (location.pathname === '/user-result' && to === URLS.USER)
              ? 'text-soft-blue underline'
              : ''
          }`
        }
      >
        {label}
      </NavLink>
    </li>
  );
};

export default NavItem;
