import React from 'react';

type ContainerPropTypes = {
  children: React.ReactNode;
  wrapperClassName?: string;
  extraContainerClassName?: string;
};

const Container = ({ children, wrapperClassName, extraContainerClassName }: ContainerPropTypes) => {
  return (
    <div className={`${wrapperClassName || ''} px-[15px] md:px-[50px]`}>
      <div className={`max-w-[1340px] w-full mx-auto ${extraContainerClassName || ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Container;
