import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';

import styles from './Header.module.scss';
import Logo from '@image/logo.png';
import CloseSquareIcon from '@/icons/CloseSquareIcon';
import NavItem from './NavItem';
import ExpandableNavItem from './ExpandableNavItem';

import { URLS, USER_ROLE } from '@/utils/constants';
import { showDesktopNavState, showMobileNavState } from '@/recoil/atom/header';
import useScreen from '@/custom-hooks/useScreen';
import useLogoutAction from '@/custom-hooks/useLogoutAction';
import HamburgerIcon from '@/icons/HamburgerIcon';
import useCheckUserRole from '@/custom-hooks/useCheckUserRole';

type menuItemType = {
  to?: URLS;
  label: string;
  items?: {
    to: URLS;
    label: string;
  }[];
};

const Header = () => {
  const location = useLocation();
  const screen = useScreen();
  const handleLogout = useLogoutAction();
  const lastScrollY = useRef(0);
  const [isHideNav, setIsHideNav] = useState(false);
  const [showingDesktopMenu, setShowingDesktopMenu] = useRecoilState(showDesktopNavState);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useRecoilState(showMobileNavState);
  const resetShowDesktopNavState = useResetRecoilState(showDesktopNavState);
  const resetShowMobileNavState = useResetRecoilState(showMobileNavState);
  const role = useCheckUserRole();
  const navMenuItems: menuItemType[] = useMemo(() => {
    const fixedItems = [
      {
        to: URLS.DASHBOARD,
        label: 'Dashboard',
      },
      { to: URLS.INSTANCE, label: 'Instances' },
      { to: URLS.WORLD, label: 'Worlds' },
      { to: URLS.GALLERY, label: 'Galleries' },
      { to: URLS.MESSAGE, label: 'Message Balls' },
      { to: URLS.USER, label: 'Users' },
      { to: URLS.SESSIONS, label: 'Sessions' },
      { to: URLS.ACTIVITIES, label: 'Activities' },
      { to: URLS.GROUP, label: 'Groups' },
    ];

    if (role.userRole === USER_ROLE.GLOBAL_ADMIN) {
      return [
        ...fixedItems,
        {
          label: 'Admin',
          items: [
            {
              to: URLS.BUILDS,
              label: 'Builds',
            },
          ],
        },
      ];
    }

    return fixedItems;
  }, [role]);

  const handleStickyNav = () => {
    if (window.scrollY > 100) {
      if (window.scrollY > lastScrollY.current) {
        setIsHideNav(true);
        lastScrollY.current = window.scrollY;
      } else {
        setIsHideNav(false);
        lastScrollY.current = window.scrollY;
      }
    } else setIsHideNav(false);
  };

  useEffect(() => {
    if (!screen.isDesktop) {
      resetShowMobileNavState();
    }
  }, [resetShowMobileNavState, location, screen.isDesktop]);

  useEffect(() => {
    const handleMenuOnResizing = () => {
      if (window.innerWidth < 1250) {
        if (showingDesktopMenu) resetShowDesktopNavState();
      } else {
        if (isOpenMobileMenu) resetShowMobileNavState();
      }
    };

    // eslint-disable-next-line
    if (typeof window !== undefined) {
      window.addEventListener('resize', handleMenuOnResizing);
    }

    return () => window.removeEventListener('resize', handleMenuOnResizing);
  }, [resetShowDesktopNavState, isOpenMobileMenu, showingDesktopMenu, resetShowMobileNavState]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!screen.isDesktop && typeof window !== undefined) {
      window.addEventListener('scroll', handleStickyNav);
    }

    return () => window.removeEventListener('scroll', handleStickyNav);
  }, [screen, screen.isDesktop]);

  return (
    <>
      <header
        className={`${styles['header-wrapper']} ${styles['sticky']} ${
          isHideNav ? styles['hide'] : ''
        }`}
      >
        <div className="flex justify-between items-center">
          <div
            className="hidden xl:flex w-[70px] h-[70px] bg-white justify-center items-center cursor-pointer"
            onClick={() => setShowingDesktopMenu(true)}
          >
            <HamburgerIcon />
          </div>
          <div
            className="w-[40px] h-[40px] flex justify-center items-center flex-col xl:hidden cursor-pointer"
            onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
          >
            <HamburgerIcon />
          </div>
          <Link to={'/'} className="mr-[0] xl:mr-[30px]">
            <img src={Logo} alt="Logo" className="max-w-[150px] xl:max-w-[170px]" />
          </Link>
        </div>
      </header>
      <div
        className={`fixed top-0 bottom-0 w-[215px] bg-white h-full z-[100] px-[24px] py-[29px] transition-all ${
          showingDesktopMenu ? 'left-0' : 'left-[-215px]'
        }`}
      >
        <div
          className="absolute top-[31px] right-[28px] cursor-pointer"
          onClick={() => setShowingDesktopMenu(false)}
        >
          <CloseSquareIcon className="stroke" />
        </div>
        <ul>
          {navMenuItems.map((item, idx) => (
            <React.Fragment key={idx}>
              {item.to ? (
                <NavItem to={item.to} label={item.label} />
              ) : (
                item.items && (
                  <ExpandableNavItem
                    label={item.label}
                    items={item.items}
                    openOnDefault={Boolean(
                      item.items.find((i) => `/${i.to}` === location.pathname),
                    )}
                  />
                )
              )}
            </React.Fragment>
          ))}
          <li
            className="text-[20px] leading-[24px] uppercase mt-[26px] transition-all cursor-pointer hover:text-soft-blue"
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </li>
        </ul>
      </div>
      <div className={`${styles['mobile-nav']} ${isOpenMobileMenu ? styles['show'] : ''}`}>
        <div className={styles['close-btn']} onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}>
          <div className={styles['wrapper']}>
            <CloseSquareIcon width={20} height={20} className="stroke" />
          </div>
        </div>
        <div className="w-full h-full flex justify-center items-center bg-gray pt-[33px] pb-[34px]">
          <div className={`w-full max-h-full overflow-y-auto m-auto ${styles['mobile-horizon']}`}>
            <ul className={`pl-[66px] pr-[61px] text-center w-full h-full`}>
              {navMenuItems.map((item, idx) => (
                <React.Fragment key={idx}>
                  {item.to ? (
                    <NavItem to={item.to} label={item.label} />
                  ) : (
                    item.items && (
                      <ExpandableNavItem
                        label={item.label}
                        items={item.items}
                        openOnDefault={Boolean(
                          item.items.find((i) => `/${i.to}` === location.pathname),
                        )}
                      />
                    )
                  )}
                </React.Fragment>
              ))}
              <li
                className="text-[20px] leading-[40px] uppercase h-[40px] mt-[16px] transition-all cursor-pointer hover:text-soft-blue"
                onClick={() => {
                  setIsOpenMobileMenu(!isOpenMobileMenu);
                  handleLogout();
                }}
              >
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Header);
