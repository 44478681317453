import React, { useState, useRef } from 'react';
import { Field } from 'formik';
import { SketchPicker } from 'react-color';

import useClickOutside from '@/custom-hooks/useClickOutside';

import styles from './CustomField.module.scss';
import { ToolTip } from '../Form';

type ColorFieldPropsType = {
  name: string;
  label: string;
  isRequired?: boolean;
  wrapperClassname?: string;
  value: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  hasTooltip?: boolean;
  tooltipHeading?: string;
  tooltipContent?: string;
  tooltipClassname?: string;
};

const ColorField = ({
  label,
  name,
  isRequired,
  wrapperClassname,
  value,
  setFieldValue,
  hasTooltip,
  tooltipContent,
  tooltipHeading,
  tooltipClassname,
}: ColorFieldPropsType) => {
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const colorOptionRef = useRef<HTMLDivElement>(null);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  useClickOutside(colorPickerRef, (e) => {
    if (colorOptionRef.current && !colorOptionRef.current.contains(e.target))
      setIsOpenPicker(false);
  });

  return (
    <div className="lg:inline-block relative">
      <div className={`flex flex-wrap items-center ${wrapperClassname || 'mb-[20px]'}`}>
        <label
          htmlFor={name}
          className="block text-[16px] leading-[24px] mr-[18px] w-full lg:w-[173px] mb-[4px] lg:mb-0 text-left lg:text-right shrink-0"
        >
          {label}
          {isRequired && <span className="text-red">*</span>}
        </label>
        <label className="relative cursor-pointer block text-[16px] leading-[24px]">
          <div
            ref={colorOptionRef}
            className={`${styles['color-select']}`}
            onClick={() => setIsOpenPicker(!isOpenPicker)}
          >
            <div className={styles['current-color']} style={{ backgroundColor: `${value}` }}></div>
          </div>
          <div
            ref={colorPickerRef}
            className={`${styles['color-picker']} ${isOpenPicker ? styles['open'] : ''}`}
          >
            <SketchPicker
              color={value}
              onChange={(color) => setFieldValue(name, color.hex)}
              disableAlpha
            />
          </div>
          <Field name={name} type="hidden" />
        </label>
      </div>
      {hasTooltip && (
        <ToolTip
          wrapperClassName={`absolute top-[0] right-[14px] lg:top-[15px] lg:right-[-47px] ${tooltipClassname}`}
          tooltipContent={tooltipContent}
          tooltipHeading={tooltipHeading}
        />
      )}
    </div>
  );
};

export default React.memo(ColorField);
