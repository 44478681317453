import React from 'react';

import styles from './Table.module.scss';
import EditIcon from '@/icons/EditIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import LogoExample from '@image/logo-example.png';
import Container from '../Container';
import ClickableHeading from './ClickableHeading';
import { SORTING_TYPE } from '@/utils/constants';

const GalleriesTable = () => {
  return (
    <Container wrapperClassName="!px-[0] md:!px-[50px] w-full" extraContainerClassName="bg-gray">
      <div className="overflow-auto ml-[22px] pt-[31px] pb-[29px] mr-[43px]">
        <div className="min-w-[1000px]">
          <table className={styles['table']}>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col style={{ width: '160px' }} />
            </colgroup>
            <thead>
              <tr className="border-[3px] border-solid border-[transparent] border-b-[#000]">
                <th colSpan={2} className="text-left">
                  <ClickableHeading
                    title="TITLE"
                    spacing={25}
                    action={() => undefined}
                    orderState={SORTING_TYPE.ASCENDING}
                  />
                </th>
                <th colSpan={2} className="text-left">
                  <ClickableHeading
                    title="ADDRESABLE"
                    spacing={11}
                    action={() => undefined}
                    orderState={SORTING_TYPE.ASCENDING}
                  />
                </th>
                <th className="text-center">ELEMENTS</th>
                <th colSpan={2} className="text-left">
                  <ClickableHeading
                    title="WORLD NAME"
                    spacing={10}
                    action={() => undefined}
                    orderState={SORTING_TYPE.ASCENDING}
                  />
                </th>
                <th className="text-center">KEY IMAGE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles['data-row']}>
                <td colSpan={2} className={`pt-[12px] pb-[9px] align-top`}>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut, magni.</p>
                </td>
                <td colSpan={2} className="pt-[12px] pb-[9px]">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, quod?</p>
                </td>
                <td className="pt-[12px] pb-[9px] align-middle text-center">7</td>
                <td colSpan={2} className="pt-[12px] pb-[9px]">
                  <button
                    // href="#"
                    className="underline underline-offset-2 transition-all hover:text-soft-blue"
                  >
                    Lorem ipsum dolor sit Lorem ipsum dolor sit
                  </button>
                </td>
                <td className="pt-[12px] pb-[9px] image">
                  <div className="flex justify-center">
                    <img src={LogoExample} alt="Example logo" />
                  </div>
                </td>

                <td className="pt-[12px] pb-[9px] icon">
                  <div className="flex justify-end">
                    <div className="cursor-pointer mr-[42px]">
                      <EditIcon />
                    </div>
                    <div className="cursor-pointer">
                      <DeleteIcon className="stroke" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default GalleriesTable;
