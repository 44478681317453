import React, { PropsWithChildren } from "react";
import {
  Route,
  useNavigate,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import AuthenticationGuard from "@/components/AuthenticationGuard";
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import {
  AUTH0_AUDIENCE_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  BASE_URL,
} from "@/config/app.config";
import ScrollToTop from "@/components/ScrollToTop";
import SamplePage from "@/Pages/SamplePage";
import { USER_ROLE } from "@/utils/constants";

const ForbiddenPage = React.lazy(() => import("@/Pages/ForbiddenPage"));
const NotFoundPage = React.lazy(() => import("@/Pages/NotFoundPage"));
const PageLogout = React.lazy(() => import("@/Pages/PageLogout"));
const CallbackAuth0Page = React.lazy(() => import("@/Pages/CallbackAuth0Page"));
const PageDashboard = React.lazy(() => import("@/Pages/PageDashboard"));
const InstanceListPage = React.lazy(
  () => import("@/Pages/InstancePages/InstanceListPage")
);
const EditInstancePage = React.lazy(
  () => import("@/Pages/InstancePages/EditPage")
);
const CreateInstancePage = React.lazy(
  () => import("@/Pages/InstancePages/CreatePage")
);
const InstanceProcessUsersFromCSVPage = React.lazy(
  () => import("@/Pages/InstancePages/InstanceProcessUsersFromCSVPage")
);
const UserSearchResultPage = React.lazy(
  () => import("@/Pages/UserPages/UserSearchResultPage")
);
const EditUserPage = React.lazy(() => import("@/Pages/UserPages/EditUserPage"));
const CreateUserPage = React.lazy(
  () => import("@/Pages/UserPages/CreateUserPage")
);
const UserSearchPage = React.lazy(
  () => import("@/Pages/UserPages/UserSearchPage")
);
const MessageListPage = React.lazy(
  () => import("@/Pages/MessagePages/MessageListPage")
);
const CreateMessagePage = React.lazy(
  () => import("@/Pages/MessagePages/CreateMessagePage")
);
const EditMessagePage = React.lazy(
  () => import("@/Pages/MessagePages/EditMessagePage")
);
const WorldListPage = React.lazy(
  () => import("@/Pages/WorldPages/WorldListPage")
);
const WorldEditPage = React.lazy(() => import("@/Pages/WorldPages/EditPage"));
const WorldCreatePage = React.lazy(
  () => import("@/Pages/WorldPages/CreateNew")
);
const GalleryListPage = React.lazy(() => import("@/Pages/GalleryPage/index"));
const SessionListPage = React.lazy(() => import("@/Pages/SessionPages/index"));
const SessionEditPage = React.lazy(
  () => import("@/Pages/SessionPages/EditPage")
);
const SessionCreatePage = React.lazy(
  () => import("@/Pages/SessionPages/CreateNew")
);
const EnrollListPage = React.lazy(
  () => import("@/Pages/EnrollPages/EnrollListPage")
);
const CreatePageGallery = React.lazy(
  () => import("@/Pages/GalleryPage/CreatePage")
);
const EditPageGallery = React.lazy(
  () => import("@/Pages/GalleryPage/EditPage")
);
const BuildListPage = React.lazy(
  () => import("@/Pages/BuildPages/BuildListPage")
);
const CreateBuildPage = React.lazy(
  () => import("@/Pages/BuildPages/CreateBuildPage")
);
const EditBuildPage = React.lazy(
  () => import("@/Pages/BuildPages/EditBuildPage")
);
const ProcessUsersFromCSVPage = React.lazy(
  () => import("@/Pages/UserPages/ProcessUsersFromCSVPage")
);

const GroupListPage = React.lazy(
  () => import("@/Pages/GroupPages/GroupListPage")
);
const CreateGroupPage = React.lazy(
  () => import("@/Pages/GroupPages/CreateGroupPage")
);
const EditGroupPage = React.lazy(
  () => import("@/Pages/GroupPages/EditGroupPage")
);
const GroupUserListPage = React.lazy(
  () => import("@/Pages/GroupPages/GroupUserListPage")
);

const ActivityListPage = React.lazy(
  () => import("@/Pages/ActivityPages/ActivityListPage")
);
const CreateActivityPage = React.lazy(
  () => import("@/Pages/ActivityPages/CreateActivityPage")
);
const EditActivityPage = React.lazy(
  () => import("@/Pages/ActivityPages/EditActivityPage")
);

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

// https://stackoverflow.com/questions/73934043/how-to-use-auth0-provider-with-the-new-createbrowsererouter-in-v6-4/73938067?noredirect=1#comment130573558_73938067
const Auth0Layout = () => (
  <Auth0ProviderWithRedirectCallback
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      audience: AUTH0_AUDIENCE_URL,
      scope: "profile email read:users",
      redirect_uri: BASE_URL + "/callback",
    }}
    cacheLocation="localstorage"
  >
    <ScrollToTop />
    <Outlet />
  </Auth0ProviderWithRedirectCallback>
);

const AppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Auth0Layout />}>
        {/* Sample page */}
        <Route path="/sample-page" element={<SamplePage />} />

        {/* World pages */}
        <Route
          path="/world"
          element={
            <AuthenticationGuard
              component={WorldListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/world/edit/:worldId"
          element={
            <AuthenticationGuard
              component={WorldEditPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/world/create"
          element={
            <AuthenticationGuard
              component={WorldCreatePage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        {/* End world pages */}

        {/* Instance pages */}
        <Route
          path="/instance"
          element={
            <AuthenticationGuard
              component={InstanceListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/instance/edit/:instanceId"
          element={
            <AuthenticationGuard
              component={EditInstancePage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/instance/create"
          element={
            <AuthenticationGuard
              component={CreateInstancePage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/instance/process-users-from-csv"
          element={
            <AuthenticationGuard
              component={InstanceProcessUsersFromCSVPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        {/* End instance pages */}
        {/* Gallery pages */}
        <Route
          path="/gallery"
          element={
            <AuthenticationGuard
              component={GalleryListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/gallery/create"
          element={
            <AuthenticationGuard
              component={CreatePageGallery}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/gallery/edit/:galleryId"
          element={
            <AuthenticationGuard
              component={EditPageGallery}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />

        {/*End Gallery pages */}
        {/* User pages */}
        <Route
          path="/user"
          element={
            <AuthenticationGuard
              component={UserSearchPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/user-result"
          element={
            <AuthenticationGuard
              component={UserSearchResultPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/user/edit/:userId"
          element={
            <AuthenticationGuard
              component={EditUserPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/user/create"
          element={
            <AuthenticationGuard
              component={CreateUserPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/user/process-users-from-csv"
          element={
            <AuthenticationGuard
              component={ProcessUsersFromCSVPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        {/* End user pages */}

        {/* Message pages */}
        <Route
          path="/message"
          element={
            <AuthenticationGuard
              component={MessageListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/message/create"
          element={
            <AuthenticationGuard
              component={CreateMessagePage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/message/edit/:messageId"
          element={
            <AuthenticationGuard
              component={EditMessagePage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        {/* End message pages */}

        {/* Session pages */}
        <Route
          path="/session"
          element={
            <AuthenticationGuard
              component={SessionListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/session/edit/:sessionId"
          element={
            <AuthenticationGuard
              component={SessionEditPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/session/create"
          element={
            <AuthenticationGuard
              component={SessionCreatePage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/session/enrol/:id"
          element={
            <AuthenticationGuard
              component={EnrollListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        {/* End session pages */}

        {/* Builds pages */}
        <Route
          path="/build"
          element={
            <AuthenticationGuard
              component={BuildListPage}
              minimumAccessLevel={USER_ROLE.GLOBAL_ADMIN}
            />
          }
        />
        <Route
          path="/build/create"
          element={
            <AuthenticationGuard
              component={CreateBuildPage}
              minimumAccessLevel={USER_ROLE.GLOBAL_ADMIN}
            />
          }
        />
        <Route
          path="/build/edit/:buildId"
          element={
            <AuthenticationGuard
              component={EditBuildPage}
              minimumAccessLevel={USER_ROLE.GLOBAL_ADMIN}
            />
          }
        />
        {/* End Builds pages */}
        {/* start group pages */}
        <Route
          path="/group"
          element={
            <AuthenticationGuard
              component={GroupListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/group/create"
          element={
            <AuthenticationGuard
              component={CreateGroupPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/group/edit/:groupId"
          element={
            <AuthenticationGuard
              component={EditGroupPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />

        <Route
          path="/group/user/:id"
          element={
            <AuthenticationGuard
              component={GroupUserListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        {/* end group pages */}
        {/* start activities pages */}
        <Route
          path="/activities"
          element={
            <AuthenticationGuard
              component={ActivityListPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/activities/create"
          element={
            <AuthenticationGuard
              component={CreateActivityPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route
          path="/activities/edit/:activityId"
          element={
            <AuthenticationGuard
              component={EditActivityPage}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />

        {/* end activities pages */}
        <Route
          path="/dashboard"
          element={
            <AuthenticationGuard
              component={PageDashboard}
              minimumAccessLevel={USER_ROLE.EVENT_ADMIN}
            />
          }
        />
        <Route path="/logout" element={<PageLogout />} />
        <Route path="/callback" element={<CallbackAuth0Page />} />
        <Route path="/forbidden" element={<ForbiddenPage />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default AppRoutes;
