import React, { FunctionComponent, SVGProps } from "react";

export const InfoIcon: FunctionComponent<SVGProps<SVGPathElement>> = (
  props
) => {
  return (
    // @ts-ignore
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9.5"
        fill={props.fill || "#E5EAEE"}
        stroke={props.stroke || "black"}
      />
      <path
        d="M9.50194 16C9.26727 16 9.06994 15.9253 8.90994 15.776C8.7606 15.616 8.68594 15.4187 8.68594 15.184V8.064C8.68594 7.81867 8.7606 7.62133 8.90994 7.472C9.06994 7.32267 9.26727 7.248 9.50194 7.248C9.74727 7.248 9.9446 7.32267 10.0939 7.472C10.2433 7.62133 10.3179 7.81867 10.3179 8.064V15.184C10.3179 15.4187 10.2433 15.616 10.0939 15.776C9.9446 15.9253 9.74727 16 9.50194 16ZM9.50194 5.824C9.21394 5.824 8.96327 5.72267 8.74994 5.52C8.54727 5.30667 8.44594 5.056 8.44594 4.768C8.44594 4.48 8.54727 4.23467 8.74994 4.032C8.96327 3.81867 9.21394 3.712 9.50194 3.712C9.78994 3.712 10.0353 3.81867 10.2379 4.032C10.4513 4.23467 10.5579 4.48 10.5579 4.768C10.5579 5.056 10.4513 5.30667 10.2379 5.52C10.0353 5.72267 9.78994 5.824 9.50194 5.824Z"
        fill={props.stroke || "black"}
      />
    </svg>
  );
};

export default InfoIcon;
